import React from "react";

// components
import Button from "~/components/shared/Button";

// utils
import { cn } from "~/utils/cn";

export enum Interval {
  // W = "1W",
  // M = "1M",
  // T = "3M",
  // Y = "1Y",

  D = "1D",
  W = "1W",
  M = "1M",
  T = "3M"
}

export const IntervalMap = {
  [Interval.D]: 2,
  [Interval.W]: 7,
  [Interval.M]: 30,
  [Interval.T]: 90
};

interface IntervalSwitchProps {
  interval: Interval;
  intervals: Interval[];
  onIntervalChange: (interval: Interval) => void;
}

export default function IntervalSwitch({ interval, intervals, onIntervalChange }: IntervalSwitchProps) {
  const [throttled, setThrottled] = React.useState<boolean>(false);

  // throttle 500ms to prevent multiple request at the same time
  const throttledIntervalChange = (selectedInterval: Interval) => {
    if (throttled || selectedInterval === interval) return;

    onIntervalChange(selectedInterval);
    setThrottled(true);

    setTimeout(() => {
      setThrottled(false);
    }, 500);
  };

  return (
    <div className="relative flex items-center w-full min-w-[180px] max-w-[200px] p-0.5 bg-card border border-solid border-border rounded-lg overflow-hidden">
      {intervals.map(int => (
        <Button
          key={int}
          type="button"
          variant="ghost"
          className={cn("relative flex-1 h-9 p-0 rounded-md hover:bg-muted/50 hover:text-inherit font-semibold z-10", {
            "hover:bg-muted font-bold": int === interval,
            "opacity-50": throttled
          })}
          onClick={() => throttledIntervalChange(int)}
          disabled={throttled}
        >
          {int}
        </Button>
      ))}

      <span
        className={cn("absolute flex w-1/4 h-9 rounded-md bg-muted z-0 transition-all duration-150", {
          "left-0.5": interval === Interval.D,
          "left-1/4": interval === Interval.W,
          "left-1/2": interval === Interval.M,
          "left-[calc(75%-2px)]": interval === Interval.T
        })}
      />
    </div>
  );
}
