/**
 * @param base string base api url
 * @param route string specified url
 * @param params object{string, any} search params
 * @returns searchparams builded url
 */
export function params_builder(
  base: string,
  route: string,
  params?: Record<string, string | number | boolean>
): string {
  const url_params = (() => {
    if (!params) return "";

    const url_params = Object.entries(params)
      .map(([key, values]) => `${key}=${encodeURIComponent(values)}`)
      .join("&");

    if (url_params === "") {
      return "";
    }

    return `?${url_params}`;
  })();

  return base + route + url_params;
}
