// immer
import { produce } from "immer";

// types
import { ImmerStateCreator } from "~/store";

type State = {
  bln_price: number | undefined;
  total_supply: number | undefined;
  circulating_supply: number | undefined;
  market_cap: number | undefined;
  bln_to_harvest: number | undefined;
  bln_to_wallet: number | undefined;
};

type Actions = {
  set_bln_price: (price: number) => void;
  set_total_supply: (supply: number) => void;
  set_circulating_supply: (supply: number) => void;
};

export type ContractSlice = State & Actions;

export const createContractSlice: ImmerStateCreator<ContractSlice> = set => ({
  bln_price: undefined,
  total_supply: undefined,
  circulating_supply: undefined,
  market_cap: undefined,
  bln_to_harvest: undefined,
  bln_to_wallet: undefined,

  set_bln_price: (price: number) => {
    set(
      produce(state => {
        state.contract.bln_price = price;
        state.contract.market_cap = state.contract.total_supply * price;
        state.contract.bln_to_harvest = state.contract.circulating_supply * price;
        state.contract.bln_to_wallet = state.contract.market_cap * price;
      })
    );
  },

  set_total_supply: (supply: number) => {
    set(
      produce(state => {
        state.contract.total_supply = supply;
      })
    );
  },

  set_circulating_supply: (supply: number) => {
    set(
      produce(state => {
        state.contract.circulating_supply = supply;
      })
    );
  }
});
