declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window {
    ethereum?: {
      request: (args: {method: string; params?: unknown[]}) => Promise<unknown>;
      on: (event: string, callback: (...args: unknown[]) => void) => void;
      removeListener: (event: string, callback: (...args: unknown[]) => void) => void;
      isMetaMask?: boolean;
      selectedAddress?: string;
      chainId?: string;
    };
  }
}

import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { FiArrowLeft, FiExternalLink, FiCopy, FiStar, FiArrowDown, FiArrowUp, FiClock, FiGlobe, FiTwitter, FiMessageCircle } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import { Eip1193Provider, ethers } from 'ethers';

import { type Token } from '~/config/tokens';
import { cn } from '~/lib/utils';

import { getDexToolsTokenInfo } from '~/utils/dextools';
import SwapSettings from '~/components/SwapSettings';

import OpenOceanWidget from '~/components/OpenOceanWidget';
import { useAccount } from 'wagmi';

interface TokenHolder {
  owner: string;
  ui_amount: number;
  percentage: number;
}

interface TokenInfo {
  name: string;
  symbol: string;
  totalSupply: string;
  decimals: number;
  marketCap: number;
  volume24h: number;
  priceChange24h: number;
  price: number;
  circulatingSupply?: number;
  holders?: number;
}

interface SocialLinks {
  website: string;
  twitter: string;
  telegram: string;
}

interface TokenTrade {
  txHash: string;
  blockUnixTime: number;
  side: 'buy' | 'sell';
  from: {
    symbol: string;
    uiAmount: number;
  };
  to: {
    symbol: string;
    uiAmount: number;
  };
  owner: string;
  tokenPrice: number;
}


// ERC20 ABI - sadece balanceOf fonksiyonu için
const ERC20_ABI = [
  {
    "constant": true,
    "inputs": [{"name": "_owner", "type": "address"}],
    "name": "balanceOf",
    "outputs": [{"name": "balance", "type": "uint256"}],
    "type": "function"
  }
];

// BirdEye API'den token bilgilerini çekme fonksiyonu
const getBirdEyeTokenInfo = async (address: string) => {
  try {
    console.log('BirdEye API isteği yapılıyor:', address);
    
    // Doğrudan API URL'sini kullan
    const response = await axios.get(
      `https://public-api.birdeye.so/defi/token_overview?address=${address}`,
      {
        headers: {
          'X-API-KEY': 'f4a5ddb746be41fe8f64db79902e29e5',
          'x-chain': 'bsc',
          'accept': 'application/json'
        }
      }
    );
    
    console.log('BirdEye API yanıtı:', response.data);
    
    if (response.data.success) {
      return response.data.data;
    }
    throw new Error('Token bilgisi alınamadı');
  } catch (error) {
    console.error('BirdEye API Error:', error);
    return null;
  }
};

// Sayıları kısaltmak için yardımcı fonksiyon
const formatNumber = (num: number | string | undefined): string => {
  if (num === undefined) return '0';
  
  const number = typeof num === 'string' ? parseFloat(num) : num;
  
  if (isNaN(number)) return '0';
  
  if (number >= 1e12) {
    return (number / 1e12).toFixed(2) + 'T';
  } else if (number >= 1e9) {
    return (number / 1e9).toFixed(2) + 'B';
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(2) + 'M';
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(2) + 'K';
  } else {
    return number.toFixed(2);
  }
};

export default function TokenDetails() {
  const { address } = useParams<{ address: string }>();
  const { address: walletAddress, isConnected } = useAccount();
  const [token, setToken] = useState<Token | null>(null);
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | null>(null);
  const [holders, setHolders] = useState<TokenHolder[]>([]);
  const [socialLinks, setSocialLinks] = useState<SocialLinks>({
    website: '',
    twitter: '',
    telegram: ''
  });
  const [isFavorite, setIsFavorite] = useState(false);
  const [activeTab, setActiveTab] = useState<'chart' | 'trades' | 'info'>('chart');
  const [pageLoading, setPageLoading] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [trades, setTrades] = useState<TokenTrade[]>([]);
  const [tradesLoading, setTradesLoading] = useState(false);
  const [userBalance, setUserBalance] = useState<string>('0.00');
  const [lastUserTrade, setLastUserTrade] = useState<TokenTrade | null>(null);
  const [balanceLoading, setBalanceLoading] = useState(false);

  const [toToken] = useState<Token>({
    name: 'BNB',
    symbol: 'BNB',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    decimals: 18,
    chainId: 56,
    logoURI: 'https://tokens.pancakeswap.finance/images/symbol/bnb.png'
  } as Token);

  // Kullanıcının token bakiyesini çek
  const fetchUserBalance = async () => {
    if (!isConnected || !walletAddress || !address || !token) return;
    
    try {
      setBalanceLoading(true);
      console.log('Token bakiyesi çekiliyor:', address, 'Cüzdan:', walletAddress);
      
      // Provider oluştur
      const provider = new ethers.BrowserProvider(window.ethereum as Eip1193Provider);
      
      // Token kontratı oluştur
      const tokenContract = new ethers.Contract(address, ERC20_ABI, provider);
      
      // Bakiyeyi çek
      const balance = await tokenContract.balanceOf(walletAddress);
      console.log('Ham bakiye:', balance.toString());
      
      // Wei'den token birimine çevir
      const formattedBalance = ethers.formatUnits(balance, token.decimals);
      console.log('Formatlanmış bakiye:', formattedBalance);
      
      setUserBalance(parseFloat(formattedBalance).toFixed(4));
    } catch (error) {
      console.error('Token bakiyesi alınamadı:', error);
      setUserBalance('0.00');
    } finally {
      setBalanceLoading(false);
    }
  };

  // Kullanıcının son işlemini bul
  const findLastUserTrade = () => {
    if (!walletAddress || trades.length === 0) return;
    
    const userTrades = trades.filter(trade => 
      trade.owner.toLowerCase() === walletAddress.toLowerCase()
    );
    
    if (userTrades.length > 0) {
      setLastUserTrade(userTrades[0]); // En son işlem
    }
  };

  // Token bilgilerini çekme
  useEffect(() => {
    const init = async () => {
      if (!address) return;
      
      setPageLoading(true);
      
      try {
        const searchAddress = address.toLowerCase();
        console.log('Token bilgileri çekiliyor:', searchAddress);
        
        // BirdEye API'den token bilgilerini çek
        const birdEyeInfo = await getBirdEyeTokenInfo(searchAddress);
        
        if (birdEyeInfo) {
          console.log('BirdEye bilgileri alındı:', birdEyeInfo);
          
          // BirdEye bilgilerini kullan
          const newToken: Token = {
            name: birdEyeInfo.name,
            symbol: birdEyeInfo.symbol,
            address: birdEyeInfo.address,
            decimals: birdEyeInfo.decimals,
            chainId: 56, // BSC
            logoURI: birdEyeInfo.logoURI,
            coinGeckoId: ''
          };
          
          setToken(newToken);
          
          // Token metrics bilgilerini ayarla
          setTokenInfo({
            name: birdEyeInfo.name,
            symbol: birdEyeInfo.symbol,
            decimals: birdEyeInfo.decimals,
            price: birdEyeInfo.price,
            priceChange24h: birdEyeInfo.priceChange24hPercent,
            marketCap: birdEyeInfo.marketCap || birdEyeInfo.fdv,
            totalSupply: birdEyeInfo.totalSupply.toString(),
            circulatingSupply: birdEyeInfo.circulatingSupply,
            holders: birdEyeInfo.holder,
            volume24h: birdEyeInfo.v24hUSD
          });
          
          // Sosyal medya bilgilerini ayarla
          if (birdEyeInfo.extensions) {
            setSocialLinks({
              website: birdEyeInfo.extensions.website || '',
              twitter: birdEyeInfo.extensions.twitter || '',
              telegram: birdEyeInfo.extensions.telegram || ''
            });
          }
        } else {
          console.log('BirdEye bilgileri alınamadı, DexTools kullanılıyor');
          // Yedek olarak DexTools'dan bilgileri çek
          const dexToolsInfo = await getDexToolsTokenInfo('bsc', searchAddress);
          
          if (!dexToolsInfo || !dexToolsInfo.info) {
            throw new Error('Token bilgisi bulunamadı');
          }
          
          // DexTools bilgilerini kullan
          const newToken: Token = {
            name: dexToolsInfo.info.name || 'Unknown Token',
            symbol: dexToolsInfo.info.symbol || 'UNKNOWN',
            address: searchAddress,
            decimals: dexToolsInfo.info.decimals || 18,
            chainId: 56, // BSC
            logoURI: dexToolsInfo.info.logoUrl || '',
            coinGeckoId: ''
          };
          
          setToken(newToken);
          
          if (dexToolsInfo.metrics) {
            setTokenInfo({
              name: dexToolsInfo.info.name,
              symbol: dexToolsInfo.info.symbol,
              decimals: dexToolsInfo.info.decimals,
              price: dexToolsInfo.metrics.price,
              priceChange24h: dexToolsInfo.metrics.priceChange24h,
              marketCap: dexToolsInfo.metrics.marketCap,
              totalSupply: dexToolsInfo.metrics.totalSupply,
              volume24h: 0
            });
          }
          
          // Sosyal medya bilgilerini ayarla
          if (dexToolsInfo.info.socialInfo) {
            setSocialLinks({
              website: dexToolsInfo.info.socialInfo.website || '',
              twitter: dexToolsInfo.info.socialInfo.twitter || '',
              telegram: dexToolsInfo.info.socialInfo.telegram || ''
            });
          }
        }

        // Token bilgileri başarıyla alındıktan sonra holder listesini çek
        if (birdEyeInfo) {
          fetchHolders();
          fetchTrades();
        }
      } catch (error) {
        console.error('Token bilgisi alınamadı:', error);

      } finally {
        setPageLoading(false);
      }
    };

    init();
  }, [address]);

  // Kullanıcı bakiyesini ve son işlemini çek
  useEffect(() => {
    if (token && isConnected && walletAddress) {
      fetchUserBalance();
    }
  }, [token, isConnected, walletAddress, address]);

  // Trades yüklendiğinde kullanıcının son işlemini bul
  useEffect(() => {
    if (trades.length > 0 && walletAddress) {
      findLastUserTrade();
    }
  }, [trades, walletAddress]);

  const fetchHolders = async () => {
    try {
      if (!address) return;
      
      console.log('Holder listesi çekiliyor:', address);
      
      // BirdEye API'den holder listesini çek - limit 20'ye çıkarıldı
      const response = await axios.get(
        `https://public-api.birdeye.so/defi/v3/token/holder?address=${address}&offset=0&limit=20`,
        {
          headers: {
            'X-API-KEY': 'f4a5ddb746be41fe8f64db79902e29e5',
            'x-chain': 'bsc',
            'accept': 'application/json'
          }
        }
      );
      
      console.log('Holder API yanıtı:', response.data);
      
      if (response.data.success && response.data.data && Array.isArray(response.data.data.items)) {
        // @ts-expect-error API yanıtı için tip tanımı yok
        const holderList = response.data.data.items.map(item => ({
          owner: item.owner || item.address,
          ui_amount: item.balance || item.amount,
          percentage: item.percentage || (Number(item.balance) / (Number(tokenInfo?.totalSupply) || 1) * 100)
        }));
        
        setHolders(holderList);
      } else {
        // API yanıtı başarısız veya beklenen formatta değilse, 20 dummy veri kullan
        const dummyHolders = Array.from({ length: 20 }, () => ({
          owner: `0x${Math.random().toString(16).slice(2, 42)}`,
          ui_amount: Math.random() * 1000000,
          percentage: Math.random() * 100,
        }));
        setHolders(dummyHolders);
      }
    } catch (error) {
      console.error('Holder listesi alınamadı:', error);
      // Hata durumunda 20 dummy veri kullan
      const dummyHolders = Array.from({ length: 20 }, () => ({
        owner: `0x${Math.random().toString(16).slice(2, 42)}`,
        ui_amount: Math.random() * 1000000,
        percentage: Math.random() * 100,
      }));
      setHolders(dummyHolders);
    }
  };

  const fetchTrades = async () => {
    try {
      if (!address) return;
      
      setTradesLoading(true);
      console.log('İşlem geçmişi çekiliyor:', address);
      
      // BirdEye API'den işlem geçmişini çek
      const response = await axios.get(
        `https://public-api.birdeye.so/defi/txs/token?address=${address}&offset=0&limit=50&tx_type=swap&sort_type=desc`,
        {
          headers: {
            'X-API-KEY': 'f4a5ddb746be41fe8f64db79902e29e5',
            'x-chain': 'bsc',
            'accept': 'application/json'
          }
        }
      );
      
      console.log('Trades API yanıtı:', response.data);
      
      if (response.data.success && response.data.data && Array.isArray(response.data.data.items)) {
        setTrades(response.data.data.items);
      }
    } catch (error) {
      console.error('İşlem geçmişi alınamadı:', error);
    } finally {
      setTradesLoading(false);
    }
  };

  if (pageLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#FFF5F7] via-white to-[#FFF5F7] pt-24">
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="flex items-center justify-center h-[400px]">
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              className="w-8 h-8 border-2 border-[#FF6B95] border-t-transparent rounded-full"
            />
          </div>
        </div>
      </div>
    );
  }

  if (!token || !tokenInfo) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#FFF5F7] via-white to-[#FFF5F7] pt-24">
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="flex items-center justify-center h-[400px]">
            <span className="text-[#805B64]">Token not found</span>
          </div>
        </div>
      </div>
    );
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FFF5F7] via-white to-[#FFF5F7] pt-24">
      <div className="max-w-screen-xl mx-auto px-4">
        {/* Token Header */}
        <div className="flex flex-col lg:flex-row gap-6 mb-8">
          {/* Sol - Token Bilgileri */}
          <div className="flex-1 bg-white rounded-3xl p-6 border border-pink-100">
            <div className="flex items-center gap-4 mb-6">
              <Link to="/swap" className="p-2 hover:bg-[#FFE4EA] rounded-xl transition-colors">
                <FiArrowLeft className="w-6 h-6 text-[#805B64]" />
              </Link>
              <div className="flex items-center gap-4">
                <img src={token.logoURI} alt={token.symbol} className="w-12 h-12 rounded-full" />
                <div>
                  <h1 className="text-2xl font-bold text-[#805B64]">{tokenInfo.name}</h1>
                  <div className="flex items-center gap-2">
                    <span className="text-[#805B64]/70">{token.symbol}</span>
                    <button onClick={() => setIsFavorite(!isFavorite)} 
                      className={cn("p-1 rounded-lg transition-colors", 
                        isFavorite ? "text-yellow-500" : "text-[#805B64]/50 hover:text-yellow-500"
                      )}>
                      <FiStar className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
              
              {/* Sosyal medya linkleri */}
              <div className="flex items-center gap-3 ml-auto">
                {socialLinks.website && (
                  <a 
                    href={socialLinks.website} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="p-2 bg-[#FFE4EA] text-[#FF6B95] rounded-full hover:bg-[#FF6B95] hover:text-white transition-colors"
                    title="Website"
                  >
                    <FiGlobe className="w-5 h-5" />
                  </a>
                )}
                {socialLinks.twitter && (
                  <a 
                    href={socialLinks.twitter} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="p-2 bg-[#FFE4EA] text-[#FF6B95] rounded-full hover:bg-[#FF6B95] hover:text-white transition-colors"
                    title="Twitter"
                  >
                    <FiTwitter className="w-5 h-5" />
                  </a>
                )}
                {socialLinks.telegram && (
                  <a 
                    href={socialLinks.telegram} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="p-2 bg-[#FFE4EA] text-[#FF6B95] rounded-full hover:bg-[#FF6B95] hover:text-white transition-colors"
                    title="Telegram"
                  >
                    <FiMessageCircle className="w-5 h-5" />
                  </a>
                )}
              </div>
            </div>

            {/* Token Stats */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div className="bg-[#FFF5F7] rounded-xl p-4">
                <div className="text-sm text-[#805B64]/70 mb-1">Price</div>
                <div className="text-xl font-bold text-[#805B64]">
                  ${tokenInfo?.price?.toFixed(6) || '0.00'}
                </div>
                <div className={cn("text-sm", 
                  tokenInfo?.priceChange24h >= 0 ? "text-green-500" : "text-red-500"
                )}>
                  {tokenInfo?.priceChange24h > 0 ? '+' : ''}
                  {tokenInfo?.priceChange24h?.toFixed(2)}%
                </div>
              </div>
              <div className="bg-[#FFF5F7] rounded-xl p-4">
                <div className="text-sm text-[#805B64]/70 mb-1">24h Volume</div>
                <div className="text-xl font-bold text-[#805B64]">
                  ${formatNumber(tokenInfo?.volume24h)}
                </div>
              </div>
              <div className="bg-[#FFF5F7] rounded-xl p-4">
                <div className="text-sm text-[#805B64]/70 mb-1">Market Cap</div>
                <div className="text-xl font-bold text-[#805B64]">
                  ${formatNumber(tokenInfo?.marketCap)}
                </div>
              </div>
              <div className="bg-[#FFF5F7] rounded-xl p-4">
                <div className="text-sm text-[#805B64]/70 mb-1">Total Supply</div>
                <div className="text-xl font-bold text-[#805B64]">
                  {formatNumber(tokenInfo?.totalSupply)}
                </div>
              </div>
            </div>
          </div>

          {/* Sağ - Quick Actions */}
          <div className="lg:w-[380px] bg-white rounded-3xl p-6 border border-pink-100">
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <span className="text-[#805B64] font-medium">Your Balance</span>
                {balanceLoading ? (
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    className="w-4 h-4 border-2 border-[#FF6B95] border-t-transparent rounded-full"
                  />
                ) : (
                  <span className="text-xl font-bold text-[#805B64]">
                    {userBalance} {token.symbol}
                  </span>
                )}
              </div>
              
              {/* Son işlem bilgisi */}
              {lastUserTrade ? (
                <div className="bg-[#FFF5F7] rounded-xl p-4">
                  <div className="flex items-center gap-2 mb-2">
                    <FiClock className="text-[#805B64]/70" />
                    <span className="text-sm font-medium text-[#805B64]">Your Last Transaction</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className={cn(
                      "flex items-center gap-1 font-medium",
                      lastUserTrade.side === 'buy' ? "text-green-500" : "text-red-500"
                    )}>
                      {lastUserTrade.side === 'buy' ? (
                        <><FiArrowUp className="w-4 h-4" /> Bought</>
                      ) : (
                        <><FiArrowDown className="w-4 h-4" /> Sold</>
                      )}
                    </div>
                    <span className="text-[#805B64]">
                      {lastUserTrade.side === 'buy' 
                        ? lastUserTrade.to.uiAmount.toFixed(2) 
                        : lastUserTrade.from.uiAmount.toFixed(2)} {token.symbol}
                    </span>
                    <span className="text-[#805B64]/70">at</span>
                    <span className="font-medium text-[#805B64]">
                      ${lastUserTrade.tokenPrice.toFixed(6)}
                    </span>
                  </div>
                  <div className="mt-2 text-xs text-[#805B64]/70">
                    {format(new Date(lastUserTrade.blockUnixTime * 1000), 'dd MMM yyyy HH:mm:ss')}
                  </div>
                </div>
              ) : isConnected ? (
                <div className="bg-[#FFF5F7] rounded-xl p-4 text-center text-[#805B64]/70">
                  No transactions found for this token
                </div>
              ) : (
                <div className="bg-[#FFF5F7] rounded-xl p-4 text-center text-[#805B64]/70">
                  Connect wallet to see your transactions
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Sol - Chart ve Bilgiler */}
          <div className="lg:col-span-2">
            <div className="bg-white rounded-3xl p-6 border border-pink-100">
              <div className="mb-6">
                <div className="flex border-b border-pink-100">
                  <button
                    onClick={() => setActiveTab('chart')}
                    className={cn(
                      "py-3 px-6 font-medium transition-colors",
                      activeTab === 'chart' 
                        ? "text-[#FF6B95] border-b-2 border-[#FF6B95]" 
                        : "text-[#805B64]/70 hover:text-[#805B64]"
                    )}
                  >
                    Chart
                  </button>
                  <button
                    onClick={() => setActiveTab('trades')}
                    className={cn(
                      "py-3 px-6 font-medium transition-colors",
                      activeTab === 'trades' 
                        ? "text-[#FF6B95] border-b-2 border-[#FF6B95]" 
                        : "text-[#805B64]/70 hover:text-[#805B64]"
                    )}
                  >
                    Trades
                  </button>
                  <button
                    onClick={() => setActiveTab('info')}
                    className={cn(
                      "py-3 px-6 font-medium transition-colors",
                      activeTab === 'info' 
                        ? "text-[#FF6B95] border-b-2 border-[#FF6B95]" 
                        : "text-[#805B64]/70 hover:text-[#805B64]"
                    )}
                  >
                    Info
                  </button>
                </div>
              </div>

              {activeTab === 'chart' && (
                <div className="bg-[#FFF5F7] rounded-xl overflow-hidden relative">
                  {/* Sabit yükseklikli, responsive iframe container */}
                  <div className="w-full h-[400px] sm:h-[450px] md:h-[500px]">
                    <iframe
                      src={`https://dexscreener.com/bsc/${address}?embed=1&theme=light&trades=0&info=0&chartTheme=light&topbarHide=1&logoHide=1`}
                      title="DexScreener Chart"
                      className="w-full h-full border-0"
                      style={{ 
                        minWidth: '100%', 
                        minHeight: '100%',
                        // DexScreener logosunu gizlemek için CSS
                        maskImage: 'linear-gradient(to bottom, white calc(100% - 30px), transparent 100%)',
                        WebkitMaskImage: 'linear-gradient(to bottom, white calc(100% - 30px), transparent 100%)'
                      }}
                    />
                  </div>
                  
                  {/* Daha küçük ve şık logo gizleme katmanı */}
                  <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-[#FFF5F7] via-[#FFF5F7]/90 to-transparent flex items-end justify-center pb-2">
                    <div className="flex items-center gap-2 px-4 py-1 bg-white/80 backdrop-blur-sm rounded-full shadow-sm">
                      <div className="w-2 h-2 rounded-full bg-green-500"></div>
                      <span className="text-xs font-medium text-[#805B64]">
                       Live {token.symbol} Price Chart
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'trades' && (
                <div className="bg-white rounded-xl overflow-hidden">
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead className="bg-[#FFF5F7]">
                        <tr>
                          <th className="py-3 px-4 text-left text-sm font-medium text-[#805B64]">Type</th>
                          <th className="py-3 px-4 text-left text-sm font-medium text-[#805B64]">Price</th>
                          <th className="py-3 px-4 text-left text-sm font-medium text-[#805B64]">Amount</th>
                          <th className="py-3 px-4 text-left text-sm font-medium text-[#805B64]">Total</th>
                          <th className="py-3 px-4 text-left text-sm font-medium text-[#805B64]">Wallet</th>
                          <th className="py-3 px-4 text-left text-sm font-medium text-[#805B64]">Time</th>
                          <th className="py-3 px-4 text-left text-sm font-medium text-[#805B64]">Tx</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-pink-100">
                        {tradesLoading ? (
                          <tr>
                            <td colSpan={7} className="py-8 text-center text-[#805B64]/70">
                              <motion.div
                                animate={{ rotate: 360 }}
                                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                                className="w-6 h-6 border-2 border-[#FF6B95] border-t-transparent rounded-full mx-auto"
                              />
                            </td>
                          </tr>
                        ) : trades.length === 0 ? (
                          <tr>
                            <td colSpan={7} className="py-8 text-center text-[#805B64]/70">
                              No trades found
                            </td>
                          </tr>
                        ) : (
                          trades.map((trade, index) => (
                            <tr key={index} className="hover:bg-[#FFF5F7]/50 transition-colors">
                              <td className="py-3 px-4">
                                <div className={cn(
                                  "flex items-center gap-1 font-medium",
                                  trade.side === 'buy' ? "text-green-500" : "text-red-500"
                                )}>
                                  {trade.side === 'buy' ? (
                                    <><FiArrowUp className="w-4 h-4" /> Buy</>
                                  ) : (
                                    <><FiArrowDown className="w-4 h-4" /> Sell</>
                                  )}
                                </div>
                              </td>
                              <td className="py-3 px-4 font-medium text-[#805B64]">
                                ${trade.tokenPrice.toFixed(8)}
                              </td>
                              <td className="py-3 px-4 text-[#805B64]">
                                {trade.side === 'buy' 
                                  ? trade.to.uiAmount.toFixed(2) 
                                  : trade.from.uiAmount.toFixed(2)} {token.symbol}
                              </td>
                              <td className="py-3 px-4 text-[#805B64]">
                                ${(trade.side === 'buy' 
                                  ? trade.from.uiAmount 
                                  : trade.to.uiAmount).toFixed(2)}
                              </td>
                              <td className="py-3 px-4">
                                <a 
                                  href={`https://bscscan.com/address/${trade.owner}`} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  className="text-[#FF6B95] hover:underline flex items-center gap-1"
                                >
                                  {trade.owner.substring(0, 6)}...{trade.owner.substring(trade.owner.length - 4)}
                                  <FiExternalLink className="w-3 h-3" />
                                </a>
                              </td>
                              <td className="py-3 px-4 text-[#805B64]">
                                {format(new Date(trade.blockUnixTime * 1000), 'HH:mm:ss')}
                              </td>
                              <td className="py-3 px-4">
                                <a 
                                  href={`https://bscscan.com/tx/${trade.txHash}`} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  className="text-[#FF6B95] hover:underline flex items-center gap-1"
                                >
                                  View <FiExternalLink className="w-3 h-3" />
                                </a>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {activeTab === 'info' && (
                <div className="space-y-4">
                  <div className="flex justify-between items-center py-3 border-b border-pink-100">
                    <span className="text-[#805B64]/70">Contract Address</span>
                    <div className="flex items-center gap-2">
                      <span className="text-[#805B64]">{token.address.slice(0, 6)}...{token.address.slice(-4)}</span>
                      <button onClick={() => copyToClipboard(token.address)} className="p-1.5 hover:bg-[#FFE4EA] rounded-lg transition-colors">
                        <FiCopy className="w-4 h-4 text-[#805B64]" />
                      </button>
                      <a href={`https://bscscan.com/token/${token.address}`} target="_blank" rel="noopener noreferrer" className="p-1.5 hover:bg-[#FFE4EA] rounded-lg transition-colors">
                        <FiExternalLink className="w-4 h-4 text-[#805B64]" />
                      </a>
                    </div>
                  </div>

                  <div className="space-y-3">
                    <div className="flex justify-between py-2">
                      <span className="text-[#805B64]/70">Chain</span>
                      <span className="text-[#805B64]">BNB Chain (BEP20)</span>
                    </div>
                    <div className="flex justify-between py-2">
                      <span className="text-[#805B64]/70">Decimals</span>
                      <span className="text-[#805B64]">{token.decimals}</span>
                    </div>
                  </div>

                  <div className="mt-6">
                    <h3 className="text-lg font-semibold text-[#805B64] mb-4">Top Holders</h3>
                    <div className="space-y-2">
                      {holders.map((holder, index) => (
                        <div key={holder.owner} className="flex justify-between items-center py-2 border-b border-pink-100">
                          <span className="text-[#805B64]/70">#{index + 1}</span>
                          <span className="text-[#805B64]">{holder.owner.slice(0, 6)}...{holder.owner.slice(-4)}</span>
                          <span className="text-[#805B64]">{holder.percentage.toFixed(2)}%</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Sağ - Swap Widget */}
          <div className="sticky top-24">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-3xl p-6 border border-pink-100"
            >
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-bold text-[#805B64]">Swap</h2>
              </div>

              <OpenOceanWidget 
                fromToken={token?.address}
                toToken={toToken?.address}
              />
            </motion.div>
          </div>
        </div>

        {/* Settings Modal */}
        <SwapSettings 
          isOpen={showSettings}
          onClose={() => setShowSettings(false)}
        />
      </div>
    </div>
  );
}