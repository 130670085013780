interface OpenOceanWidgetProps {
  fromToken?: string;
  toToken?: string;
}

export default function OpenOceanWidget({ fromToken, toToken }: OpenOceanWidgetProps) {
  const widgetUrl = `https://widget.openocean.finance?p=JTIzRkZGNEY3JTI0KiUyNCUyM0ZGRjRGNyUyNColMjQlMjNGRkY0RjclMjQqJTI0JTIzRkZGNEY3JTI0KiUyNCUyM0E1ODk5MCUyNColMjQlMjM4QzdGOEMlMjQqJTI0JTIzZmI1MzRmJTI0KiUyNCUyM2ZmZmZmZiUyNColMjQlMjNGRkU0RUElMjQqJTI0JTIzYjFhN2IxJTI0KiUyNCUyMzQ3OWE0YiUyNColMjQlMjNmNzUwMjklMjQqJTI0Q3JlcGUlMjBGaW5hbmNlJTI0KiUyNFJvYm90byUyNColMjQlMjQqJTI0T21yJTI0KiUyNDB4MTUzOWQxRjY4Y2EzMEIzNjEyOGYzMDJkYWJDQ2M1Q2Q2MEYxRmVjNCUyNColMjQxJTI0KiUyNGJzYyUyNColMjRCTkIlMjQqJTI0Q0FLRSUyNColMjQ=&from=${fromToken}&to=${toToken}`;

  return (
    <iframe
      src={widgetUrl}
      className="w-full h-[600px] rounded-xl"
      frameBorder="0"
    />
  );
}