import axios from 'axios';

const DEXTOOLS_API_KEY = 's642nkuTAd32wwVbBM3lQ3E3s1cZvnYf1rhoMfGT';
const DEXTOOLS_API_URL = 'https://public-api.dextools.io/trial/v2';


export const getDexToolsTokenInfo = async (chain: string, address: string) => {
  try {
    const [tokenResponse, priceResponse, infoResponse] = await Promise.all([
      axios.get(`${DEXTOOLS_API_URL}/token/${chain}/${address}`, {
        headers: { 'X-API-Key': DEXTOOLS_API_KEY }
      }),
      axios.get(`${DEXTOOLS_API_URL}/token/${chain}/${address}/price`, {
        headers: { 'X-API-Key': DEXTOOLS_API_KEY }
      }),
      axios.get(`${DEXTOOLS_API_URL}/token/${chain}/${address}/info`, {
        headers: { 'X-API-Key': DEXTOOLS_API_KEY }
      })
    ]);

    return {
      info: {
        name: tokenResponse.data.data.name,
        symbol: tokenResponse.data.data.symbol,
        decimals: tokenResponse.data.data.decimals,
        logoUrl: tokenResponse.data.data.logo,
        description: tokenResponse.data.data.description,
        socialInfo: tokenResponse.data.data.socialInfo
      },
      metrics: {
        price: priceResponse.data.data.price,
        priceChange24h: priceResponse.data.data.variation24h,
        price24h: priceResponse.data.data.price24h,
        marketCap: infoResponse.data.data.mcap,
        totalSupply: infoResponse.data.data.totalSupply.toString(),
        circulatingSupply: infoResponse.data.data.circulatingSupply,
        holders: infoResponse.data.data.holders
      }
    };
  } catch (error) {
    console.error('DexTools API Error:', error);
    return {};
  }
};