// types
import type * as types from "~/types/api.types";

// utils
import { cookies } from "~/utils/cookies";

// config
import { Constants } from "~/config";

// utils
import { storage } from "~/utils/storage";

interface TokenInfo {
  name: string;
  symbol: string;
  decimals: number;
  logoURI: string;
  address: string;
  chainId: number;
  coinGeckoId?: string;
}

export async function fetchTokenInfo(address: string, chainId: number): Promise<TokenInfo> {
  try {
    // Önce Coingecko'dan deneyelim
    const response = await fetch(
      `https://api.coingecko.com/api/v3/coins/ethereum/contract/${address}`
    );
    
    if (response.ok) {
      const data = await response.json();
      return {
        name: data.name,
        symbol: data.symbol.toUpperCase(),
        decimals: 18, // Varsayılan olarak 18, contract'tan güncellenecek
        logoURI: data.image.small,
        address: address,
        chainId: chainId,
        coinGeckoId: data.id
      };
    }

    // Coingecko başarısız olursa 1inch API'yi deneyelim
    const oneInchResponse = await fetch(
      `https://tokens.1inch.io/v1.1/${chainId}/${address}`
    );
    
    if (oneInchResponse.ok) {
      const data = await oneInchResponse.json();
      return {
        name: data.name,
        symbol: data.symbol,
        decimals: data.decimals,
        logoURI: data.logoURI,
        address: address,
        chainId: chainId
      };
    }

    throw new Error('Token info not found');
  } catch (error) {
    console.error('Error fetching token info:', error);
    throw error;
  }
}

class Api {
  public base = import.meta.env.VITE_SWAP_API_URL // "https://swap-api.balancenetwork.io";
  private access_token = cookies.get(Constants.Authentication);

  private prepare_headers(headers: Record<string, string> | undefined, isJson?: boolean) {
    const configuredHeader: Record<string, string> = {
      Authorization: `Bearer ${this.access_token}`,
      "x-bn-dex-identifier": storage.get(Constants.Authentication),
      "x-bn-dex-signature": storage.get(Constants.Signature),
      "x-bn-dex-timestamp": storage.get(Constants.Timestamp),
      ...(isJson && {
        "Content-Type": "application/json"
      }),
      ...headers
    };

    return configuredHeader;
  }

  public async call<T>(params: types.CallParams): Promise<types.Result<T>> {
    const external = params.url.startsWith("http");

    const data = await fetch(external ? params.url : this.base + params.url, {
      method: params.method,
      headers: this.prepare_headers(params.headers, params.method !== "GET"),
      body: params.method !== "GET" ? (JSON.stringify(params.body) as BodyInit) : null
    });

    if (!data.ok) {
      return (await data.json()) as types.FailureResponse;
    }

    return (await data.json()) as T;
  }
}

const api = new Api();
export { api };
