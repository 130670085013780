import React from "react";
import ReactDOM from "react-dom/client";

// styles
import "~/assets/globals.css";

// providers
import Providers from "~/providers";

// contexts
import { AuthContextProvider } from "~/contexts/auth";

// router
import Router from "~/router";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthContextProvider>
      <Providers>
        <Router />
      </Providers>
    </AuthContextProvider>
  </React.StrictMode>
);
