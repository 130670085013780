// immer
import { produce } from "immer";

// types
import type { ImmerStateCreator } from "~/store";
import type { TokenPrice } from "~/types/coingecko.types";

type State = {
  tokens: Map<string, number>;
};

type Actions = {
  set_tokens: (tokens: TokenPrice) => void;
};

export type MarketSlice = State & Actions;

export const createMarketSlice: ImmerStateCreator<MarketSlice> = set => ({
  tokens: new Map<string, number>([]),

  set_tokens: (token: TokenPrice) => {
    set(
      produce(state => {
        (state.market.tokens as Map<string, number>).set(Object.keys(token)[0] as string, Object.values(token)[0].usd);
      })
    );
  }
});
