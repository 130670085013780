import { motion } from 'framer-motion';

const networks = [
  {
    name: 'Metis',
    icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDI0QzE4LjYyNzQgMjQgMjQgMTguNjI3NCAyNCAxMkMyNCA1LjM3MjU4IDE4LjYyNzQgMCAxMiAwQzUuMzcyNTggMCAwIDUuMzcyNTggMCAxMkMwIDE4LjYyNzQgNS4zNzI1OCAyNCAxMiAyNFoiIGZpbGw9IiMyMUZGQjUiLz4KPHBhdGggZD0iTTcuNSA2TDEyIDlMMTYuNSA2TDE4IDguNUwxMiAxMkw2IDguNUw3LjUgNloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02IDEyTDEyIDE1LjVMMTggMTJMMTYuNSAxNC41TDEyIDE3LjVMNy41IDE0LjVMNiAxMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=',
  },
  {
    name: 'BNB Chain',
    icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDI0QzE4LjYyNzQgMjQgMjQgMTguNjI3NCAyNCAxMkMyNCA1LjM3MjU4IDE4LjYyNzQgMCAxMiAwQzUuMzcyNTggMCAwIDUuMzcyNTggMCAxMkMwIDE4LjYyNzQgNS4zNzI1OCAyNCAxMiAyNFoiIGZpbGw9IiNGMEIwMEYiLz4KPHBhdGggZD0iTTcuNSA2TDEyIDlMMTYuNSA2TDE4IDguNUwxMiAxMkw2IDguNUw3LjUgNloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02IDEyTDEyIDE1LjVMMTggMTJMMTYuNSAxNC41TDEyIDE3LjVMNy41IDE0LjVMNiAxMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=',
  },
  {
    name: 'Moonbeam',
    icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDI0QzE4LjYyNzQgMjQgMjQgMTguNjI3NCAyNCAxMkMyNCA1LjM3MjU4IDE4LjYyNzQgMCAxMiAwQzUuMzcyNTggMCAwIDUuMzcyNTggMCAxMkMwIDE4LjYyNzQgNS4zNzI1OCAyNCAxMiAyNFoiIGZpbGw9IiM1M0NCRjUiLz4KPHBhdGggZD0iTTcuNSA2TDEyIDlMMTYuNSA2TDE4IDguNUwxMiAxMkw2IDguNUw3LjUgNloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02IDEyTDEyIDE1LjVMMTggMTJMMTYuNSAxNC41TDEyIDE3LjVMNy41IDE0LjVMNiAxMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=',
  },
  {
    name: 'Polygon',
    icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDI0QzE4LjYyNzQgMjQgMjQgMTguNjI3NCAyNCAxMkMyNCA1LjM3MjU4IDE4LjYyNzQgMCAxMiAwQzUuMzcyNTggMCAwIDUuMzcyNTggMCAxMkMwIDE4LjYyNzQgNS4zNzI1OCAyNCAxMiAyNFoiIGZpbGw9IiM4MjQ3RTUiLz4KPHBhdGggZD0iTTcuNSA2TDEyIDlMMTYuNSA2TDE4IDguNUwxMiAxMkw2IDguNUw3LjUgNloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02IDEyTDEyIDE1LjVMMTggMTJMMTYuNSAxNC41TDEyIDE3LjVMNy41IDE0LjVMNiAxMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=',
  },
  {
    name: 'Aurora',
    icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDI0QzE4LjYyNzQgMjQgMjQgMTguNjI3NCAyNCAxMkMyNCA1LjM3MjU4IDE4LjYyNzQgMCAxMiAwQzUuMzcyNTggMCAwIDUuMzcyNTggMCAxMkMwIDE4LjYyNzQgNS4zNzI1OCAyNCAxMiAyNFoiIGZpbGw9IiM3MkJGNjUiLz4KPHBhdGggZD0iTTcuNSA2TDEyIDlMMTYuNSA2TDE4IDguNUwxMiAxMkw2IDguNUw3LjUgNloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02IDEyTDEyIDE1LjVMMTggMTJMMTYuNSAxNC41TDEyIDE3LjVMNy41IDE0LjVMNiAxMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=',
  },
  {
    name: 'Ethereum',
    icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDI0QzE4LjYyNzQgMjQgMjQgMTguNjI3NCAyNCAxMkMyNCA1LjM3MjU4IDE4LjYyNzQgMCAxMiAwQzUuMzcyNTggMCAwIDUuMzcyNTggMCAxMkMwIDE4LjYyNzQgNS4zNzI1OCAyNCAxMiAyNFoiIGZpbGw9IiM2MjdFRUEiLz4KPHBhdGggZD0iTTcuNSA2TDEyIDlMMTYuNSA2TDE4IDguNUwxMiAxMkw2IDguNUw3LjUgNloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02IDEyTDEyIDE1LjVMMTggMTJMMTYuNSAxNC41TDEyIDE3LjVMNy41IDE0LjVMNiAxMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=',
  },
  {
    name: 'Fantom',
    icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDI0QzE4LjYyNzQgMjQgMjQgMTguNjI3NCAyNCAxMkMyNCA1LjM3MjU4IDE4LjYyNzQgMCAxMiAwQzUuMzcyNTggMCAwIDUuMzcyNTggMCAxMkMwIDE4LjYyNzQgNS4zNzI1OCAyNCAxMiAyNFoiIGZpbGw9IiMxOTY5RkYiLz4KPHBhdGggZD0iTTcuNSA2TDEyIDlMMTYuNSA2TDE4IDguNUwxMiAxMkw2IDguNUw3LjUgNloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02IDEyTDEyIDE1LjVMMTggMTJMMTYuNSAxNC41TDEyIDE3LjVMNy41IDE0LjVMNiAxMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=',
  },
  {
    name: 'Huobi ECO',
    icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDI0QzE4LjYyNzQgMjQgMjQgMTguNjI3NCAyNCAxMkMyNCA1LjM3MjU4IDE4LjYyNzQgMCAxMiAwQzUuMzcyNTggMCAwIDUuMzcyNTggMCAxMkMwIDE4LjYyNzQgNS4zNzI1OCAyNCAxMiAyNFoiIGZpbGw9IiMwMEExRUUiLz4KPHBhdGggZD0iTTcuNSA2TDEyIDlMMTYuNSA2TDE4IDguNUwxMiAxMkw2IDguNUw3LjUgNloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02IDEyTDEyIDE1LjVMMTggMTJMMTYuNSAxNC41TDEyIDE3LjVMNy41IDE0LjVMNiAxMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=',
  },
  {
    name: 'Moonriver',
    icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDI0QzE4LjYyNzQgMjQgMjQgMTguNjI3NCAyNCAxMkMyNCA1LjM3MjU4IDE4LjYyNzQgMCAxMiAwQzUuMzcyNTggMCAwIDUuMzcyNTggMCAxMkMwIDE4LjYyNzQgNS4zNzI1OCAyNCAxMiAyNFoiIGZpbGw9IiNGRkZGRkYiLz4KPHBhdGggZD0iTTcuNSA2TDEyIDlMMTYuNSA2TDE4IDguNUwxMiAxMkw2IDguNUw3LjUgNloiIGZpbGw9IiM1M0NCRjUiLz4KPHBhdGggZD0iTTYgMTJMMTIgMTUuNUwxOCAxMkwxNi41IDE0LjVMMTIgMTcuNUw3LjUgMTQuNUw2IDEyWiIgZmlsbD0iIzUzQ0JGNSIvPgo8L3N2Zz4K',
  },
  {
    name: 'OKC',
    icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyIDI0QzE4LjYyNzQgMjQgMjQgMTguNjI3NCAyNCAxMkMyNCA1LjM3MjU4IDE4LjYyNzQgMCAxMiAwQzUuMzcyNTggMCAwIDUuMzcyNTggMCAxMkMwIDE4LjYyNzQgNS4zNzI1OCAyNCAxMiAyNFoiIGZpbGw9IiMwMDAwMDAiLz4KPHBhdGggZD0iTTcuNSA2TDEyIDlMMTYuNSA2TDE4IDguNUwxMiAxMkw2IDguNUw3LjUgNloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik02IDEyTDEyIDE1LjVMMTggMTJMMTYuNSAxNC41TDEyIDE3LjVMNy41IDE0LjVMNiAxMloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=',
  }
];

export default function SupportedNetworks() {
  return (
    <section className="py-20 bg-[#FFF5F7]">
      <div className="max-w-screen-xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-[#805B64] mb-4">
            Upgrade your trades with
          </h2>
          <p className="text-2xl">
            <span className="text-[#8247E5] font-semibold">cross-chain</span>
            <span className="text-[#805B64] font-semibold"> swaps on various networks</span>
          </p>
        </motion.div>

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6 gap-4">
          {networks.map((network, index) => (
            <motion.div
              key={network.name}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="flex flex-col items-center gap-3 p-4 bg-white rounded-xl hover:shadow-lg transition-all cursor-pointer"
            >
              <img 
                src={network.icon} 
                alt={network.name}
                className="w-12 h-12"
              />
              <span className="text-sm font-medium text-[#805B64]">
                {network.name}
              </span>
            </motion.div>
          ))}
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: networks.length * 0.1 }}
            className="flex flex-col items-center justify-center gap-2 p-4 bg-white rounded-xl border-2 border-dashed border-[#FFE4EA]"
          >
            <span className="text-sm font-medium text-[#805B64]">More</span>
            <span className="text-xs text-[#805B64]/60">upcoming</span>
          </motion.div>
        </div>
      </div>
    </section>
  );
}