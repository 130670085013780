import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { FiX } from 'react-icons/fi';
import { getSwapSettings, saveSwapSettings } from '~/utils/0x';
import { cn } from '~/lib/utils';

interface SwapSettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SwapSettings({ isOpen, onClose }: SwapSettingsProps) {
  const [settings, setSettings] = useState(getSwapSettings());

  const handleSave = () => {
    saveSwapSettings(settings);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-white rounded-3xl p-6 w-full max-w-md">
          <div className="flex items-center justify-between mb-6">
            <Dialog.Title className="text-xl font-bold text-[#805B64]">
              Swap Settings
            </Dialog.Title>
            <button onClick={onClose} className="p-2 hover:bg-[#FFE4EA] rounded-xl transition-colors">
              <FiX className="w-5 h-5 text-[#805B64]" />
            </button>
          </div>

          <div className="space-y-6">
            {/* Slippage Tolerance */}
            <div>
              <label className="text-sm text-[#805B64]/70 mb-2 block">
                Slippage Tolerance
              </label>
              <div className="grid grid-cols-4 gap-2">
                <button
                  onClick={() => setSettings({ ...settings, slippagePercentage: '0.1' })}
                  className={cn(
                    "px-3 py-2 rounded-xl text-sm font-medium",
                    settings.slippagePercentage === '0.1' 
                      ? 'bg-[#FF6B95] text-white' 
                      : 'bg-[#FFE4EA] text-[#805B64]'
                  )}
                >
                  0.1%
                </button>
                <button
                  onClick={() => setSettings({ ...settings, slippagePercentage: '0.5' })}
                  className={cn(
                    "px-3 py-2 rounded-xl text-sm font-medium",
                    settings.slippagePercentage === '0.5'
                      ? 'bg-[#FF6B95] text-white'
                      : 'bg-[#FFE4EA] text-[#805B64]'
                  )}
                >
                  0.5%
                </button>
                <button
                  onClick={() => setSettings({ ...settings, slippagePercentage: '1' })}
                  className={cn(
                    "px-3 py-2 rounded-xl text-sm font-medium",
                    settings.slippagePercentage === '1'
                      ? 'bg-[#FF6B95] text-white'
                      : 'bg-[#FFE4EA] text-[#805B64]'
                  )}
                >
                  1%
                </button>
                <div className="relative">
                  <input
                    type="number"
                    value={settings.slippagePercentage}
                    onChange={(e) => setSettings({ ...settings, slippagePercentage: e.target.value })}
                    className="w-full px-3 py-2 bg-[#FFE4EA] rounded-xl text-[#805B64] text-sm outline-none"
                    placeholder="Custom"
                  />
                  <span className="absolute right-3 top-1/2 -translate-y-1/2 text-[#805B64] text-sm">%</span>
                </div>
              </div>
            </div>

            {/* Transaction Deadline */}
            <div>
              <label className="text-sm text-[#805B64]/70 mb-2 block">
                Transaction Deadline
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="number"
                  value={settings.deadline}
                  onChange={(e) => setSettings({ ...settings, deadline: e.target.value })}
                  className="flex-1 px-4 py-2 bg-[#FFE4EA] rounded-xl text-[#805B64] outline-none"
                />
                <span className="text-[#805B64]">minutes</span>
              </div>
            </div>

            <button
              onClick={handleSave}
              className="w-full py-4 bg-[#FF6B95] text-white font-semibold rounded-xl hover:bg-[#FF6B95]/90 transition-colors"
            >
              Save Settings
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}