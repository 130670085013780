// immer
import { produce } from "immer";

// types
import type { ImmerStateCreator } from "~/store";
import { CoinGeckoCoin } from '~/types/CoinGecko';
import type { CoinGeckoCoinList, CoinGeckoGraph, CoinGeckoMarket } from "~/types/coingecko.types";
import { Interval } from "~/components/IntervalSwitch";

type State = {
  feeRecipient:string
  zeroXFeePercentage:number
  coins: CoinGeckoCoinList | undefined;
  base_token?: CoinGeckoCoin;
  quote_token?: CoinGeckoCoin;
  inputs: {
    base_price: string;
    quote_price: string;
    pool_base: string;
    pool_quote: string;
  };
  interval: Interval;
  slippage: string;
  deadline: number;
  change: number | undefined;
  price: number | undefined;
  graph: CoinGeckoGraph | undefined | "failed";
  base_market: CoinGeckoMarket | undefined;
  cursor_pos: string | undefined;
};

type Actions = {
  set_fee_recipient: (recipient: string) => void;
  set_zerox_fee_percentage: (fee: number) => void;
  set_coins: (coins: CoinGeckoCoinList) => void;
  set_base_token: (token: CoinGeckoCoin) => void;
  set_quote_token: (token: CoinGeckoCoin) => void;
  set_inputs: (name: string, value: string) => void;
  set_interval: (interval: Interval) => void;
  set_slippage: (slippage: string) => void;
  set_deadline: (deadline: number) => void;
  set_change: (change: number) => void;
  set_price: (price: number) => void;
  set_graph: (graph: CoinGeckoGraph | "failed") => void;
  set_base_market: (market: CoinGeckoMarket | undefined) => void;
  set_cursor_pos: (position: string) => void;
};

export type SwapSlice = State & Actions;

export const createSwapSlice: ImmerStateCreator<SwapSlice> = set => ({
  feeRecipient:'0xB561F4a25CC8955E86d357cFcbF07fb3858ad2Bd', // initial state, the true one will come from request
  zeroXFeePercentage:0.01, // initial state, the true one will come from request
  coins: undefined,
  base_token: undefined,
  quote_token: undefined,
  inputs: {
    base_price: "",
    quote_price: "",
    pool_base: "",
    pool_quote: ""
  },
  interval: Interval.D,
  slippage: "0.5",
  deadline: 10,
  change: undefined,
  price: undefined,
  graph: undefined,
  base_market: undefined,
  cursor_pos: undefined,

  set_coins: (coins: CoinGeckoCoinList) => {
    set(
      produce(state => {
        state.swap.coins = coins;
      })
    );
  },
  set_base_token: (token: CoinGeckoCoin) => {
    set(
      produce(state => {
        state.swap.base_token = token;
      })
    );
  },
  set_quote_token: (token: CoinGeckoCoin) => {
    set(
      produce(state => {
        state.swap.quote_token = token;
      })
    );
  },
  set_interval: (interval: Interval) => {
    set(
      produce(state => {
        state.swap.interval = interval;
      })
    );
  },
  set_slippage: (slippage: string) => {
    set(
      produce(state => {
        state.swap.slippage = slippage;
      })
    );
  },
  set_deadline: (deadline: number) => {
    set(
      produce(state => {
        state.swap.deadline = deadline;
      })
    );
  },
  set_change: (change: number) => {
    set(
      produce(state => {
        state.swap.change = change;
      })
    );
  },
  set_price: (price: number) => {
    set(
      produce(state => {
        state.swap.price = price;
      })
    );
  },
  set_graph: (graph: CoinGeckoGraph | "failed") => {
    set(
      produce(state => {
        state.swap.graph = graph;
      })
    );
  },
  set_base_market: (market: CoinGeckoMarket | undefined) => {
    set(
      produce(state => {
        state.swap.base_market = market;
      })
    );
  },
  set_cursor_pos: (position: string) => {
    set(
      produce(state => {
        state.swap.cursor_pos = position;
      })
    );
  },
  set_inputs: (name, value) => {
    set(
      produce(state => {
        state.swap.inputs[name] = value;
      })
    );
  },
  set_fee_recipient: (recipient) => {
    set(
      produce(state => {
        state.swap.feeRecipient = recipient;
      })
    );
  },
  set_zerox_fee_percentage: (fee) => {
    set(
      produce(state => {
        state.swap.zeroXFeePercentage = fee;
      })
    );
  }
});
