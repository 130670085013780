import { FC } from 'react';

interface DefaultTokenIconProps {
  className?: string;
}

const DefaultTokenIcon: FC<DefaultTokenIconProps> = ({ className = "w-8 h-8" }) => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#E2E8F0"/>
      <path
        d="M12 6L14.5 9H9.5L12 6Z"
        fill="#94A3B8"
      />
      <path
        d="M12 18L9.5 15H14.5L12 18Z"
        fill="#94A3B8"
      />
      <rect
        x="9"
        y="10"
        width="6"
        height="4"
        fill="#94A3B8"
      />
    </svg>
  );
};

export default DefaultTokenIcon; 