import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";

// layout
import AppLayout from "~/components/Layout";

// routes
import Index from "~/routes/index";
import RemoveLiquidity from "~/routes/remove-liquidity";

// lazy routes
const Swap = React.lazy(() => import("~/routes/swap"));
const Pool = React.lazy(() => import("~/routes/pool"));
 const AddPool = React.lazy(() => import("~/routes/add-pool"));
// const Farms = React.lazy(() => import("~/routes/farms"));
// const Stake = React.lazy(() => import("~/routes/stake"));
const Bridge = React.lazy(() => import("~/routes/bridge"));
import TokenDetail from "~/routes/token/[address]";


const router = createHashRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Index />
      },
      {
        path: "swap",
        element: (
          <React.Suspense fallback={<></>}>
            <Swap />
          </React.Suspense>
        )
      },
      {
        path: "pool",
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<></>}>
                <Pool />
              </React.Suspense>
            )
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<></>}>
                <AddPool />
              </React.Suspense>
            ),
            children: [
              {
                path: ":pair",
                element: (
                  <React.Suspense fallback={<></>}>
                    <AddPool /> 
                  </React.Suspense>
                )
              }
            ]
          },
          {
            path: "remove",
            element: (
              <React.Suspense fallback={<></>}>
                <RemoveLiquidity />
              </React.Suspense>
            ),
            children: [
              {
                path: ":pair",
                element: (
                  <React.Suspense fallback={<></>}>
                    <RemoveLiquidity />
                  </React.Suspense>
                )
              }
            ]
          }
        ]
      },
      // {
      //   path: "farms",
      //   children: [
      //     {
      //       index: true,
      //       element: (
      //         <React.Suspense fallback={<></>}>
      //           <Farms />
      //         </React.Suspense>
      //       )
      //     }
      //   ]
      // },
      // {
      //   path: "stake",
      //   children: [
      //     {
      //       index: true,
      //       element: (
      //         <React.Suspense fallback={<></>}>
      //           <Stake />
      //         </React.Suspense>
      //       )
      //     }
      //   ]
      // },
      {
        path: "bridge",
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<></>}>
                <Bridge />
              </React.Suspense>
            )
          }
        ]
      },
      {
        path: "/token/:address",
        element: <TokenDetail />,
      },
    ]
  }
]);

export default function Router() {
  return <RouterProvider router={router} />;
}
