// zustand
import { create, type StateCreator } from "zustand";

// immer
import { enableMapSet } from "immer";

// middlewares
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

// slices
import { createContractSlice, type ContractSlice } from "~/store/contract";
import { createSwapSlice, type SwapSlice } from "~/store/swap";
import { createMarketSlice, type MarketSlice } from "~/store/market";
import { createFarmsSlice, FarmsSlice } from "~/store/farms";


enableMapSet();

export type ImmerStateCreator<T> = StateCreator<T, [["zustand/immer", never], never], [], T>;

export type AppStore = {
  contract: ContractSlice;
  market: MarketSlice;
  swap: SwapSlice;
  farms: FarmsSlice;
};

export const useAppStore = create<AppStore>()(
  devtools(
    immer((...args) => ({
      // @ts-expect-error kalkacak
      contract: createContractSlice(...args),
      // @ts-expect-error kalkacak
      market: createMarketSlice(...args),
      // @ts-expect-error kalkacak
      swap: createSwapSlice(...args),
      // @ts-expect-error kalkacak
      farms: createFarmsSlice(...args)
    }))
  )
);


