import React from "react";
import { Link, NavLink, Outlet, ScrollRestoration, useLocation } from "react-router-dom";

// framer-motion
import { AnimatePresence, motion } from "framer-motion";

// hooks
import { useCoinList, useTokenPrice } from "~/hooks/useCoinGeckoQuery";


// components
import Icon from "~/components/shared/Icon";
import Image from "~/components/shared/Image";
import ConnectWallet from "~/components/ConnectWallet";
import { Toaster } from "~/components/shared/Toaster";
import MobileNavbar from './MobileNavbar';
import SearchBar from './SearchBar';
import TokenSlider from "./TokenSlider";

// config
import { Images } from "~/config";

// utils
import { cn } from "~/utils/cn";




export default function AppLayout() {
  const location = useLocation();

  // amount of bln token, if it will use equal a constant
  useTokenPrice("balance-network");

  useCoinList();

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FFF5F7] via-white to-[#FFF5F7]">
      <header className="fixed top-0 left-0 right-0 bg-white border-b border-pink-100 z-50">
        <div className="container mx-auto px-4 h-20 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <Link to="/" className="flex items-center">
              <Image 
                src={Images.Logo} 
                alt="Crepe | Dex" 
                width={160} 
                height={42} 
                className="object-contain"
              />
            </Link>
          </div>
          
          <div className="hidden md:block w-[240px]">
            <SearchBar />
          </div>
          
          <nav className="hidden md:flex items-center space-x-8">
            {[
              { path: '/swap', label: 'TopList', icon: 'toplist', disabled: false },
              { path: '/pool', label: 'Pool', icon: 'pool', disabled: false },
              { path: '/bridge', label: 'Bridge', icon: 'bridge', disabled: false },
              { path: '#', label: 'Farms', icon: 'farms', disabled: true },
              { path: '#', label: 'Staking', icon: 'staking', disabled: true },
              { path: '#', label: 'Launchpad', icon: 'launchpad', disabled: true },
            ].map((item) => (
              item.disabled ? (
                <div
                  key={item.path}
                  className="flex items-center gap-1 text-sm font-medium text-[#805B64]/50 cursor-not-allowed"
                  title="Yakında"
                >
                  <Icon icon={item.icon} className="w-4 h-4 opacity-50" />
                  {item.label}
                  <span className="ml-1 text-xs px-1.5 py-0.5 bg-[#FFE4EA] text-[#FF6B95] rounded-full">Soon</span>
                </div>
              ) : (
                <NavLink
                  key={item.path}
                  to={item.path}
                  className={({ isActive }) =>
                    cn(
                      "flex items-center gap-1 text-sm font-medium",
                      isActive ? "text-[#FF6B95]" : "text-[#805B64] hover:text-[#FF6B95]"
                    )
                  }
                >
                  <Icon icon={item.icon} className="w-4 h-4" />
                  {item.label}
                </NavLink>
              )
            ))}
          </nav>
          
          <ConnectWallet />
        </div>
        
        <TokenSlider />
      </header>

      <AnimatePresence mode="wait">
        <motion.main
          key={location.pathname}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.2 }}
          className="pt-32"
        >
          <Outlet />
        </motion.main>
      </AnimatePresence>

      <MobileNavbar />
      <Toaster />
      <AppFooter />
      <ScrollRestoration />
    </div>
  );
}

function AppFooter(): JSX.Element {
  return (
    <footer className="py-8 bg-white">
      {/* footer içeriği */}
    </footer>
  );
}

interface SocialMediaLinkProps {
  link: {
    name: string;
    icon: string;
    to: string;
  };
}

export function SocialMediaLink({ link }: SocialMediaLinkProps) {
  return (
    <Link
      to={link.to}
      target="_blank"
      rel="noreferrer noopenner"
      className="flex w-5 h-5 transition-opacity duration-150 hover:opacity-80"
    >
      <Icon icon={link.icon} />
      <span className="sr-only">{link.name}</span>
    </Link>
  );
}

/**
 *
 * Shared layout
 */
interface DefaultLayoutProps {
  children: React.ReactNode;
  className?: string;
}

export function Container({ children, className }: DefaultLayoutProps) {
  return <div className={cn("block w-full max-w-[1560px] mx-auto px-3 md:px-10", className)}>{children}</div>;
}

interface SectionProps extends DefaultLayoutProps {
  title?: string;
  className?: string;
}

export function Section({ title, className, children }: SectionProps) {
  return (
    <section className={cn("flex flex-1 flex-col gap-y-2 py-8", className)}>
      {title && <h3 className="text-xl sm:text-3xl font-bold">{title}</h3>}

      <div className="flex flex-1 flex-col">{children}</div>
    </section>
  );
}
