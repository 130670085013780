import { parse, stringify } from "~/utils/parse";

class Storage {
  private storage: typeof window.localStorage | undefined = undefined;

  // initialize storage on create
  public initialize() {
    if (!window.localStorage) {
      throw new Error("[Storage] Instance must be served in window object.");
    }

    this.storage = window.localStorage;
  }

  // read storage
  public get(key: string) {
    if (!this.storage) return undefined;

    const property = this.storage.getItem(key);

    if (property === undefined || property === null) {
      return undefined;
    }

    return parse(property);
  }

  // write storage
  public set<T>(key: string, value: T) {
    if (!this.storage) return undefined;

    if (typeof key === "undefined") {
      throw new Error("[Storage] Key is must be set.");
    }

    if (typeof value === "undefined") {
      throw new Error("[Storage] Value is must be set.");
    }

    const stringifiedValue = stringify(value);
    this.storage.setItem(key, stringifiedValue);
  }

  // remove storage
  public remove(key: string) {
    if (!this.storage) return undefined;

    if (typeof key === "undefined" || typeof key !== "string") {
      throw new Error(`[Storage] Key is must be set in string. ID: ${key || "none"}`);
    }

    this.storage.removeItem(key);
  }
}

export const storage = new Storage();
storage.initialize();
