// cookies
import Universal from "universal-cookie";

// utils
import * as format from "~/utils/parse";

interface CookieOptions {
  path?: string; // cookie path, use / as the path if you want your cookie to be accessible on all pages
  expires?: Date; // absolute expiration date for the cookie
  maxAge?: number; // relative max age of the cookie from when the client receives it in seconds
  domain?: string; // domain for the cookie (sub.domain.com or .allsubdomains.com)
  secure?: boolean; // Is only accessible through HTTPS?
  httpOnly?: boolean; // Is only the server can access the cookie? Note: You cannot get or set httpOnly cookies from the browser, only the server.
  sameSite?: boolean | "none" | "lax" | "strict"; // Strict or Lax enforcement
}

class Cookies {
  private origin = "/";
  private cookies = new Universal(null, { path: this.origin });

  public getAll() {
    return this.cookies;
  }

  public get(key: string) {
    const cookie = this.cookies.get(key);

    if (!cookie) return undefined;

    return format.parse(cookie);
  }

  public set(key: string, value: string, options?: CookieOptions) {
    if (!key || !value) {
      throw new Error("[Cookies] SET: key or value must be provided.");
    }

    this.cookies.set(key, format.stringify(value), options);
  }

  public remove(key: string) {
    if (!key) {
      throw new Error("[Cookies] REMOVE: key must be provided.");
    }

    this.cookies.remove(key);
  }
}

const cookies = new Cookies();
export { cookies };
