import { motion } from "framer-motion";
import { FiArrowRight, FiRepeat, FiDroplet, FiTrendingUp } from "react-icons/fi";
import { Link } from "react-router-dom";

const products = [
  {
    icon: FiRepeat,
    title: "Swap",
    description: "Instantly swap tokens across multiple chains with the best rates",
    link: "/token/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    stats: {
      value: "$500M+",
      label: "24h Volume"
    }
  },
  {
    icon: FiDroplet,
    title: "Liquidity",
    description: "Provide liquidity to earn passive income from trading fees",
    link: "/pool",
    stats: {
      value: "$1.2B+",
      label: "Total Value Locked"
    }
  },
  {
    icon: FiTrendingUp,
    title: "Bridge",
    description: "Transfer your assets seamlessly between different blockchains",
    link: "/bridge",
    stats: {
      value: "10+",
      label: "Supported Chains"
    }
  }
];

export default function ProductCards() {
  return (
    <div className="w-full bg-gradient-to-br from-[#FFF5F7] via-white to-[#FFF5F7] py-16">
      <div className="max-w-screen-xl mx-auto px-4">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-[#805B64] mb-4">
            Our Products
          </h2>
          <p className="text-lg text-[#805B64]/80 max-w-2xl mx-auto">
            Experience the full suite of DeFi products designed for seamless cross-chain trading
          </p>
        </motion.div>

        {/* Product Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <motion.div
              key={product.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="group relative"
            >
              <Link to={product.link}>
                <div className="h-full bg-white rounded-2xl p-8 border border-pink-100 
                              shadow-sm hover:shadow-xl transition-all duration-300
                              hover:border-[#FF6B95]/20">
                  {/* Icon */}
                  <div className="w-16 h-16 bg-gradient-to-br from-[#FFE4EA] to-[#FFF5F7] 
                                rounded-2xl flex items-center justify-center mb-6
                                group-hover:scale-110 transition-transform duration-300">
                    <product.icon className="w-8 h-8 text-[#FF6B95]" />
                  </div>

                  {/* Content */}
                  <h3 className="text-2xl font-bold text-[#805B64] mb-3">
                    {product.title}
                  </h3>
                  <p className="text-[#805B64]/70 mb-6">
                    {product.description}
                  </p>

                  {/* Stats */}
                  <div className="flex items-center justify-between mt-auto">
                    <div className="w-10 h-10 bg-[#FFE4EA] rounded-xl flex items-center justify-center
                                  group-hover:bg-[#FF6B95] transition-colors duration-300">
                      <FiArrowRight className="w-5 h-5 text-[#FF6B95] group-hover:text-white" />
                    </div>
                  </div>

                  {/* Decorative Background Icon */}
                  <div className="absolute top-8 right-8 opacity-5">
                    <product.icon className="w-32 h-32 text-[#FF6B95]" />
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>

        {/* Additional Features */}
        <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[
            { label: "Total Users", value: "2K+" },
            { label: "Daily Transactions", value: "2K+" },
            { label: "Available Tokens", value: "1000+" },
            { label: "Trading Volume", value: "100K+" }
          ].map((stat, index) => (
            <motion.div
              key={stat.label}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 + index * 0.1 }}
              className="bg-white p-6 rounded-xl border border-pink-100 text-center"
            >
              <div className="text-2xl font-bold text-[#FF6B95] mb-1">
                {stat.value}
              </div>
              <div className="text-[#805B64]/70">
                {stat.label}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
} 