import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import DefaultTokenIcon from '~/components/DefaultTokenIcon';

interface TokenSearchResult {
  address: string;
  name: string;
  symbol: string;
  logoURI?: string;
}

interface DexScreenerBaseToken {
  address: string;
  name: string;
  symbol: string;
}

interface DexScreenerPair {
  baseToken: DexScreenerBaseToken;
  chainId: string;
  dexId: string;
}

interface DexScreenerResponse {
  pairs?: DexScreenerPair[];
}

export default function SearchBar() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<TokenSearchResult[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const searchTokens = async (searchQuery: string) => {
    if (!searchQuery) {
      setResults([]);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`https://api.dexscreener.com/latest/dex/search?q=${searchQuery}`);
      const data: DexScreenerResponse = await response.json();
      
      if (data.pairs) {
        const bscPairs = data.pairs.filter(pair => pair.chainId === "bsc");
        
        const tokens = bscPairs.map((pair) => ({
          address: pair.baseToken.address,
          name: pair.baseToken.name,
          symbol: pair.baseToken.symbol,
        }));
        
        const uniqueTokens = Array.from(
          new Map(tokens.map(token => [token.address, token]))
          .values()
        ).slice(0, 5);

        setResults(uniqueTokens);
      }
    } catch (error) {
      console.error('Error searching tokens:', error);
    }
    setLoading(false);
  };

  const handleSearch = (value: string) => {
    setQuery(value);
    setIsOpen(true);
    
    // Debounce search
    const timeoutId = setTimeout(() => {
      searchTokens(value);
    }, 300);

    return () => clearTimeout(timeoutId);
  };

  const handleSelectToken = (token: TokenSearchResult) => {
    navigate(`/token/${token.address}`);
    setIsOpen(false);
    setQuery('');
  };

  return (
    <div ref={searchRef} className="relative w-full max-w-md search-container">
      <div className="relative">
        <input
          type="text"
          value={query}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search by name or paste address"
          className="w-full px-5 py-4 bg-white rounded-2xl border border-pink-100 
                   text-[#805B64] placeholder-[#805B64]/50 outline-none focus:border-[#FF6B95]
                   transition-colors pr-12"
        />
        <div className="absolute right-4 top-1/2 -translate-y-1/2 text-[#FF6B95]">
          {loading ? (
            <div className="animate-spin">
              <FiSearch className="w-5 h-5" />
            </div>
          ) : (
            <FiSearch className="w-5 h-5" />
          )}
        </div>
      </div>

      {isOpen && (results.length > 0 || loading) && (
        <div className="absolute mt-2 w-full bg-white rounded-2xl border border-pink-100 shadow-lg max-h-[300px] overflow-y-auto z-50">
          <div className="p-2">
            {loading ? (
              <div className="p-4 text-center text-[#805B64]/70">
                Searching...
              </div>
            ) : (
              results.map((token) => (
                <button
                  key={token.address}
                  onClick={() => handleSelectToken(token)}
                  className="w-full flex items-center gap-3 p-3 hover:bg-[#FFF5F7] rounded-xl transition-colors"
                >
                  <div className="w-8 h-8 flex items-center justify-center overflow-hidden rounded-full bg-[#FFE4EA]">
                    {token.logoURI ? (
                      <img src={token.logoURI} alt={token.symbol} className="w-6 h-6" />
                    ) : (
                      <DefaultTokenIcon className="w-6 h-6" />
                    )}
                  </div>
                  <div className="flex-1 text-left">
                    <div className="font-medium text-[#805B64]">{token.symbol}</div>
                    <div className="text-sm text-[#805B64]/70">{token.name}</div>
                  </div>
                </button>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
} 