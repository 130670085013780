enum Constants {
  Theme = "bn-theme",
  Language = "bn-lang",
  Authentication = "bn-session",
  Connector = "bn-connector",
  Signature = "bn-signature",
  Timestamp = "bn-timestamp"
}

export default Constants;
