import { motion } from "framer-motion";
import { FiLayers, FiShield, FiTrendingUp, FiGlobe } from "react-icons/fi";

export default function HybridLiquidity() {
  const features = [
    {
      icon: FiLayers,
      title: "Multi-Chain Liquidity",
      description: "Access deep liquidity pools across multiple blockchains for better trading efficiency"
    },
    {
      icon: FiShield,
      title: "Secure Protocol",
      description: "Advanced security measures to protect your assets during cross-chain transactions"
    },
    {
      icon: FiTrendingUp,
      title: "Optimal Rates",
      description: "Smart routing algorithm finds the best rates across all supported chains"
    },
    {
      icon: FiGlobe,
      title: "Global Access",
      description: "Trade seamlessly across different networks from a single interface"
    }
  ];

  return (
    <div className="w-full bg-gradient-to-br from-[#FFF5F7] via-white to-[#FFF5F7] py-16">
      <div className="max-w-screen-xl mx-auto px-4">
        {/* Header Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-[#805B64] mb-4">
            Hybrid Liquidity Protocol
          </h2>
          <p className="text-lg text-[#805B64]/80 max-w-2xl mx-auto">
            Experience the next generation of DeFi with our innovative hybrid liquidity solution,
            combining the best of centralized and decentralized exchanges.
          </p>
        </motion.div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="group relative"
            >
              {/* Card */}
              <div className="h-full bg-white rounded-2xl p-6 border border-pink-100 
                            shadow-sm hover:shadow-md transition-all duration-300">
                {/* Icon Container */}
                <div className="w-14 h-14 bg-gradient-to-br from-[#FFE4EA] to-[#FFF5F7] 
                              rounded-xl flex items-center justify-center mb-6 
                              group-hover:scale-110 transition-transform duration-300">
                  <feature.icon className="w-7 h-7 text-[#FF6B95]" />
                </div>

                {/* Content */}
                <h3 className="text-xl font-semibold text-[#805B64] mb-3">
                  {feature.title}
                </h3>
                <p className="text-[#805B64]/70">
                  {feature.description}
                </p>

                {/* Decorative Elements */}
                <div className="absolute top-4 right-4 opacity-5">
                  <feature.icon className="w-24 h-24 text-[#FF6B95]" />
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
} 