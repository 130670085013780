import React from "react";

// config
import { Constants } from "~/config";

// utils
import { storage } from "~/utils/storage";

export enum AuthStatus {
  CONNECTED,
  DISCONNECTED
}

interface AuthBase {
  account: string | undefined;
  status: AuthStatus;
}

interface AuthContextProps extends AuthBase {
  connect: (account: string) => void;
  disconnect: () => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
  account: undefined,
  status: AuthStatus.DISCONNECTED,
  connect: () => {},
  disconnect: () => {}
});

const INITIAL_AUTH_STATUS: AuthBase = {
  account: undefined,
  status: AuthStatus.DISCONNECTED
};

export function AuthContextProvider({ children }: { children: React.ReactNode }) {
  const [authentication, setAuthentication] = React.useState<AuthBase>(INITIAL_AUTH_STATUS);

  // handle storage on mount
  React.useEffect(() => {
    const session = storage.get(Constants.Authentication);

    if (session === undefined) return;

    setAuthentication({
      account: session,
      status: AuthStatus.CONNECTED
    });
  }, []);

  // handle connect
  function connect(account: string) {
    setAuthentication({
      account,
      status: AuthStatus.CONNECTED
    });
  }

  // handle disconnect
  function disconnect() {
    setAuthentication(INITIAL_AUTH_STATUS);
  }

  const provider: AuthContextProps = {
    ...authentication,
    connect,
    disconnect
  };

  return <AuthContext.Provider value={provider}>{children}</AuthContext.Provider>;
}
