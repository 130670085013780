import React from "react";

// react-query
import { useQuery, UseQueryResult } from "react-query";

// store
import { useAppStore } from "~/store";

// utils
import { fetchers } from "~/utils/fetchers";

// types
import type { FailureResponse, Result } from "~/types/api.types";
import type { CoinGeckoCoinList, CoinGeckoGraph, CoinGeckoMarket, TokenPrice } from "~/types/coingecko.types";

/**
 * Get coin list hook
 */
type CoinListQuery = Result<CoinGeckoCoinList>;
export function useCoinList(): UseQueryResult<CoinListQuery> {
  const swap = useAppStore(store => store.swap);
  const query = useQuery<CoinListQuery>("coin_list", fetchers.get_token_list);

  React.useEffect(() => {
    if (query.isFetched && query.isSuccess) {
      const data = query.data as CoinGeckoCoinList;

      swap.set_coins(data);
    }
  }, [query]);

  return query;
}

/**
 * Get token price hook
 */
type TokenPriceQuery = Result<TokenPrice>;
export function useTokenPrice(token?: string): UseQueryResult<TokenPriceQuery> {
  const [market, contract] = useAppStore(store => [store.market, store.contract]);
  const query = useQuery<TokenPriceQuery>(["token_prices", token], () => fetchers.get_token_price(token!), {
    enabled: !!token
  });

  React.useEffect(() => {
    if (query.isFetched && query.isSuccess) {
      const data = query.data as TokenPrice;

      if (token === "balance-network") {
        contract.set_bln_price(data["balance-network"]?.usd);
      }

      market.set_tokens(data);
    }
  }, [query]);

  return query;
}

/**
 * Get token market hook
 */
type TokenMarketQuery = Result<CoinGeckoMarket>;
export function useTokenMarket(token?: string): UseQueryResult<TokenMarketQuery> {
  const swap = useAppStore(store => store.swap);
  const query = useQuery<TokenMarketQuery>(["token_market", token], () => fetchers.get_token_market(token!), {
    cacheTime: 0,
    refetchInterval: 60 * 1000, // a minute in ms
    enabled: !!token
  });

  React.useEffect(() => {
    if (query.isFetched && query.isSuccess && !(query.data as FailureResponse).error) {
      const data = query.data as CoinGeckoMarket;
      swap.set_base_market(data);
    }
  }, [query]);

  return query;
}

/**
 * Get token graph hook
 */
type TokenGraphQuery = Result<CoinGeckoGraph>;
export function useTokenGraph(token: string | undefined, interval: number | "max"): UseQueryResult<TokenGraphQuery> {
  const set_graph = useAppStore(store => store.swap.set_graph);
  const query = useQuery<TokenGraphQuery>(
    ["token_graph", interval, token],
    () => fetchers.get_token_graph(token!, interval),
    {
      cacheTime: 0,
      refetchInterval: 5 * 60 * 1000, // five minute in ms,
      enabled: !!token
    }
  );

  React.useEffect(() => {
    if (query.isFetched && query.isSuccess) {
      const data = query.data as CoinGeckoGraph;

      set_graph(data);
    } else {
      set_graph("failed");
    }
  }, [query]);

  return query;
}
