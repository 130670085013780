import axios from 'axios';
import zeroxProxy from '~/config/zeroxProxy.json';
import { ZeroXSwapParams } from '~/types/0x.types';
import { Address } from '~/types/global.types';
import * as web3_utils from 'web3-utils';

const API_KEY = '23d7d204-a634-47be-bf71-dcae2ee795cc';
const API_URL = 'https://bsc.api.0x.org';
const BSC_PROXY = zeroxProxy['56'].exchangeProxy;

// Affiliate fee ayarları
const AFFILIATE_FEE = 0.01;
const FEE_RECIPIENT = "0x75A94931B81d81C7a62b76DC0FcFAC77FbE1e917";

export const getQuote = async (params: ZeroXSwapParams) => {
  try {
    const sellAmountWei = web3_utils.toWei(params.sellAmount.toString(), 'ether');

    const response = await axios.get(`${API_URL}/swap/v2/quote`, {
      params: {
        sellToken: params.sellToken,
        buyToken: params.buyToken,
        sellAmount: sellAmountWei,
        takerAddress: params.takerAddress,
        feeRecipient: params.feeRecipient || FEE_RECIPIENT,
        buyTokenPercentageFee: params.buyTokenPercentageFee || AFFILIATE_FEE,
        slippagePercentage: params.slippagePercentage
      },
      headers: {
        '0x-api-key': API_KEY
      }
    });

    return {
      to: BSC_PROXY as Address,
      ...response.data
    };
  } catch (error) {
    console.error('Quote Error:', error);
    throw error;
  }
};

export const getPrice = async (params: {
  sellToken: Address;
  buyToken: Address;
  sellAmount: string;
  takerAddress?: Address;
}) => {
  try {
    const sellAmountWei = web3_utils.toWei(params.sellAmount || '1', 'ether');

    const response = await axios.get(`${API_URL}/swap/v2/price`, {
      params: {
        sellToken: params.sellToken,
        buyToken: params.buyToken,
        sellAmount: sellAmountWei,
        takerAddress: params.takerAddress
      },
      headers: {
        '0x-api-key': API_KEY
      }
    });

    return {
      buyAmount: response.data.buyAmount,
      price: web3_utils.fromWei(response.data.price, 'ether'),
      estimatedPriceImpact: response.data.estimatedPriceImpact,
      sellAmount: response.data.sellAmount,
      allowanceTarget: response.data.allowanceTarget,
      sources: response.data.sources
    };
  } catch (error) {
    console.error('Price Error:', error);
    throw error;
  }
};

export const executeSwap = async (params: ZeroXSwapParams) => {
  try {
    const quote = await getQuote(params);
    return {
      to: quote.to,
      data: quote.data,
      value: quote.value,
      gas: quote.gas,
      gasPrice: quote.gasPrice
    };
  } catch (error) {
    console.error('Swap Error:', error);
    throw error;
  }
};

// Settings helpers
export const getSwapSettings = () => {
  return {
    slippagePercentage: localStorage.getItem('slippagePercentage') || '0.5',
    deadline: localStorage.getItem('deadline') || '20'
  };
};

export const saveSwapSettings = (settings: {
  slippagePercentage: string;
  deadline: string;
}) => {
  localStorage.setItem('slippagePercentage', settings.slippagePercentage);
  localStorage.setItem('deadline', settings.deadline);
};