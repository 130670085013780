import React from "react";
import { NavLink } from "react-router-dom";

// components
import Button from "~/components/shared/Button";

export default function TabNavigator() {
  const tabs = React.useMemo(() => {
    return ["swap", "liquidity", "bridge"];
  }, []);

  return (
    <div className="flex items-stretch gap-x-3">
      {tabs.map(tab => {
        return (
          <NavLink key={tab} to={`/${tab === "liquidity" ? "pool" : tab}`}>
            {({ isActive }) => (
              <Button
                type="button"
                variant={isActive ? "secondary" : "border"}
                size="xlarge"
                className="min-w-[116px] capitalize"
              >
                {tab}
              </Button>
            )}
          </NavLink>
        );
      })}
    </div>
  );
}
