// components
import SearchBar from "~/components/SearchBar";
import AnnouncementSlider from "~/components/AnnouncementSlider";
import TokenStats from "~/components/TokenStats";
import HybridLiquidity from "~/components/HybridLiquidity";
import ProductCards from "~/components/ProductCards";
import Roadmap from "~/components/Roadmap";
import JoinCommunity from "~/components/JoinCommunity";
import { Link } from "react-router-dom";
import SupportedNetworks from '~/components/SupportedNetworks';

// hooks
import { useContractStats } from "~/hooks/useContractQuery";

import { motion } from "framer-motion";

// Görseli doğrudan import edelim
import crepeImage from '../assets/images/crepebyke.png';

export default function Index() {
  useContractStats();

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#FFF5F7] to-white">
      {/* Hero Section */}
      <section className="pt-32 pb-20">
        <div className="max-w-screen-xl mx-auto px-4">

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            {/* Sol - İçerik */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="space-y-8"
            >
              <h1 className="text-5xl lg:text-6xl font-bold">
                <span className="text-[#805B64]">Trade Crypto</span>
                <br />
                <span className="text-[#FF6B95]">with Crepe</span>
              </h1>
              <p className="text-lg text-[#805B64]/80 max-w-lg">
                Crepe is decentralized exchange platform with BNB rewards and hybrid liquid protocol, useful and simple latest solutions for investors, traders and businesses.
              </p>

              {/* Search Bar */}
              <SearchBar />

              <div className="flex gap-4 pt-4">
                <Link 
                  to="/token/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" 
                  className="px-8 py-4 bg-[#FF6B95] text-white rounded-xl font-semibold hover:bg-[#FF6B95]/90 transition-colors"
                >
                  Start Trading
                </Link>
                <a 
                  href="#learn-more" 
                  className="px-8 py-4 bg-[#FFE4EA] text-[#FF6B95] rounded-xl font-semibold hover:bg-[#FFE4EA]/80 transition-colors"
                >
                  Learn More
                </a>
              </div>
            </motion.div>

            {/* Sağ - Crepe Görseli */}
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="relative flex items-center justify-center"
            >
              <div className="relative w-full max-w-[600px]">
                {/* Dekoratif arka plan efekti */}
                <div 
                  className="absolute -inset-4 bg-gradient-to-r from-pink-200/30 via-pink-300/20 to-transparent 
                           rounded-full blur-3xl -z-10"
                />
                
                {/* Ana görsel */}
                <motion.img 
                  src={crepeImage}
                  alt="Crepe Finance"
                  className="w-full h-auto object-contain z-10 drop-shadow-xl"
                  initial={{ y: 20 }}
                  animate={{ y: 0 }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut"
                  }}
                />

                {/* Parlama efekti */}
                <div className="absolute inset-0 bg-gradient-to-tr from-pink-200/10 via-transparent to-pink-100/20 
                              rounded-full mix-blend-overlay" />
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Supported Networks */}
      <SupportedNetworks />

      {/* Diğer bölümler */}
      <AnnouncementSlider />
      <TokenStats />
      <HybridLiquidity />
      <ProductCards />
      <Roadmap />
      <JoinCommunity />

      {/* Copyright ve İletişim Bilgisi - Daha kompakt tasarım */}
      <div className="w-full py-4 bg-white border-t border-pink-100">
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="text-center text-xs">
            <p className="text-[#805B64]/70">
              &copy; {new Date().getFullYear()} Crepe Finance. All rights reserved. 
              <a href="mailto:info@crepe.fun" className="text-[#FF6B95] hover:underline ml-2">
                info@crepe.fun
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

