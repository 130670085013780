import { motion } from "framer-motion";
import { FiCheck, FiClock } from "react-icons/fi";

const roadmapItems = [
  {
    quarter: "Q2 2025",
    title: "Platform Launch",
    items: [
      { text: "DEX Launch", completed: true },
      { text: "Initial Token Launch", completed: true },
      { text: "Basic Swap Features", completed: true },
      { text: "Community Building", completed: true },
      { text: "Advanced Trading Tools", completed: true }
    ]
  },
  {
    quarter: "Q3 2025",
    title: "Enhanced Features",
    items: [
      { text: "Major Exchange Listing", completed: false },
      { text: "Launchpad ", completed: false },
      { text: "Liquidity Mining Program", completed: false },
      { text: "Mobile App Beta", completed: false }
    ]
  },
  {
    quarter: "Q4 2025",
    title: "Ecosystem Expansion",
    items: [
      { text: "Governance Implementation", completed: false },
      { text: "NFT Integration", completed: false },
      { text: "Additional Chain Support", completed: false },
      { text: "Advanced Analytics Dashboard", completed: false }
    ]
  },
  {
    quarter: "Q1 2026",
    title: "Platform Maturity",
    items: [
      { text: "DAO Launch", completed: false },
      { text: "Cross-chain Lending", completed: false },
      { text: "Mobile App Launch", completed: false },
      { text: "Enterprise Partnerships", completed: false }
    ]
  }
];

export default function Roadmap() {
  return (
    <div className="w-full bg-gradient-to-br from-[#FFF5F7] via-white to-[#FFF5F7] py-20">
      <div className="max-w-screen-xl mx-auto px-4">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-[#805B64] mb-4">
            Our Roadmap
          </h2>
          <p className="text-lg text-[#805B64]/80 max-w-2xl mx-auto">
            Follow our journey as we build the future of decentralized finance
          </p>
        </motion.div>

        {/* Timeline */}
        <div className="relative">
          {/* Center Line */}
          <div className="absolute left-1/2 transform -translate-x-1/2 w-px h-full bg-gradient-to-b from-[#FFE4EA] to-[#FF6B95]/20" />

          {/* Roadmap Items */}
          <div className="space-y-16">
            {roadmapItems.map((phase, phaseIndex) => (
              <motion.div
                key={phase.quarter}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: phaseIndex * 0.1 }}
                className={`relative flex ${
                  phaseIndex % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                } gap-8 md:gap-16`}
              >
                {/* Quarter Circle */}
                <div className="absolute left-1/2 top-0 transform -translate-x-1/2 
                              w-12 h-12 bg-white rounded-full border-4 border-[#FF6B95] z-10" />

                {/* Content */}
                <div className={`flex-1 ${phaseIndex % 2 === 0 ? "md:text-right" : "md:text-left"}`}>
                  <div className="bg-white rounded-2xl p-8 border border-pink-100 shadow-sm
                                hover:shadow-lg transition-all duration-300">
                    <div className="inline-block px-4 py-2 bg-[#FFE4EA] rounded-full text-[#FF6B95] 
                                  font-semibold mb-4">
                      {phase.quarter}
                    </div>
                    <h3 className="text-2xl font-bold text-[#805B64] mb-6">
                      {phase.title}
                    </h3>
                    <ul className="space-y-4">
                      {phase.items.map((item, index) => (
                        <motion.li
                          key={index}
                          initial={{ opacity: 0, x: phaseIndex % 2 === 0 ? 20 : -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.3, delay: index * 0.1 }}
                          className="flex items-center gap-3"
                        >
                          <div className={`w-6 h-6 rounded-full flex items-center justify-center
                                        ${item.completed ? 'bg-green-100 text-green-500' : 'bg-[#FFE4EA] text-[#FF6B95]'}`}>
                            {item.completed ? <FiCheck /> : <FiClock />}
                          </div>
                          <span className={`flex-1 ${item.completed ? 'text-[#805B64]' : 'text-[#805B64]/70'}`}>
                            {item.text}
                          </span>
                        </motion.li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* Spacer for alternating layout */}
                <div className="flex-1 hidden md:block" />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
} 