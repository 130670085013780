import React from "react";

// config
import { Constants } from "~/config";

// utils
import { storage } from "~/utils/storage";

export enum Theme {
  DARK = "dark",
  LIGHT = "light"
}

interface ThemeContextProps {
  theme: Theme;
  changeTheme: (theme: Theme) => void;
}

export const ThemeContext = React.createContext<ThemeContextProps>({
  theme: Theme.DARK,
  changeTheme: () => {}
});

export function ThemeContextProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = React.useState<Theme>(Theme.LIGHT);

  // handle storage on mount
  React.useEffect(() => {
    const savedTheme = storage.get(Constants.Theme);

    if (savedTheme === undefined) return;

    setTheme(savedTheme);
  }, []);

  // handle classes when theme changes
  React.useEffect(() => {
    if (theme === Theme.DARK) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [theme]);

  // handle change theme
  function changeTheme(theme: Theme) {
    setTheme(theme);
    storage.set(Constants.Theme, theme);
  }

  const provider = {
    theme,
    changeTheme
  };

  return <ThemeContext.Provider value={provider}>{children}</ThemeContext.Provider>;
}
