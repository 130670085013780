import { WEB3ERRORS } from "~/config/web3";
import { toast } from "~/hooks";

/**
 * Returns web3 error toast
 * @param error Error error
 * @returns toast
 */
export function errorToast(error: Error) {
  console.log(error.message);

  const errorMessage = WEB3ERRORS.find(e => error.message.includes(e))
    ?.replaceAll("_", " ")
    .toLowerCase();

  if (!errorMessage)
    return toast({
      title: "Error",
      description: "An error occured"
    });

  return toast({
    title: "Error",
    description: errorMessage?.charAt(0).toUpperCase() + errorMessage?.slice(1)
  });
}
