import { Address } from "wagmi";
import * as web3_utils from "web3-utils";

// types
import { CoinGeckoCoinList, CoinGeckoGraph, CoinGeckoMarket, TokenPrice } from "~/types/coingecko.types";
import type { ContractStatsResponse } from "~/types/contract.types";
import { FailureResponse, Result } from "~/types/api.types";
import { Pool, PoolResponse } from "~/types/pool.types";
import { ZeroXPrice } from "~/types/0x.types";

// utils
import { api } from "~/utils/api";
import { params_builder } from "~/utils/url";
import { ConfigResponse, SwapHistoryBody, SwapHistoryResponse, SwapResponse } from "~/types/swap.types";
import { FarmsResponse } from "~/types/farms.type";
import { StakesResponse } from "~/types/stake.types";

/**
 * fetcher functions' provider
 * all requests eventually sent from here.
 */
class Fetchers {
  /**
   * Auth fetchers
   */
  public async sign_account(wallet_address: string) {
    return await api.call<unknown>({
      url: "/api/auth/sign",
      method: "POST",
      body: {
        wallet_address
      }
    });
  }

  /**
   * Coin fetchers
   */
  public async get_token_list() {
    return await api.call<CoinGeckoCoinList>({
      method: "GET",
      url: `/api/coin/list`
    });
  }

  /**
   * Contract Fetchers
   */
  public async get_contract_stats() {
    return await api.call<ContractStatsResponse>({
      method: "GET",
      url: "/api/contract/stats"
    });
  }

  /**
   * CoinGecko Fetchers
   */
  public async get_token_price(token: string) {
    return await api.call<TokenPrice>({
      method: "POST",
      url: `/api/cg/prices/tokens`,
      body: { token: token }
    });
  }

  public async get_token_market(token: string) {
    return await api.call<CoinGeckoMarket>({
      method: "GET",
      url: `/api/cg/market/${token}`
    });
  }

  public async get_token_graph(token: string, interval: number | "max") {
    const builded_url = params_builder(api.base, "/api/cg/chart", {
      interval: "daily",
      days: interval || 30,
      token
    });

    return await api.call<CoinGeckoGraph>({
      method: "GET",
      url: builded_url
    });
  }

  public async get_convert_token({
    baseTokenAddress,
    quoteTokenAddress,
    price,
    network
  }: {
    baseTokenAddress: string;
    quoteTokenAddress: string;
    price: string;
    network: number | undefined;
  }): Promise<Result<ZeroXPrice>> {
    const url = params_builder(api.base, `/api/0x/price/${network}`, {
      sellToken: baseTokenAddress,
      buyToken: quoteTokenAddress,
      sellAmount: web3_utils.toWei(price, "ether")
    });

    return await api.call<ZeroXPrice>({
      url,
      method: "GET"
    });
  }


  public async get_user_pools(user: Address, chainId: string): Promise<PoolResponse[] | FailureResponse> {
    return await api.call<Promise<PoolResponse[]>>({
      method: "GET",
      url: `/api/pool?user=${user}&network=${chainId}`,
      headers: {
        user,
        network: chainId
      }
    });
  }

  public async get_pool(pair: string): Promise<PoolResponse | FailureResponse> {
    return await api.call<Promise<PoolResponse>>({
      method: "GET",
      url: `/api/pool/${pair}`
    });
  }

  public async create_pool(body: Pool) {
    return await api.call({ url: "/api/pool", method: "POST", body });
  }

  public async update_pool({
    update_type,
    poolAddress,
    body
  }: {
    update_type: "add" | "remove";
    poolAddress: string;
    body: Partial<Pool>;
  }) {
    return await api.call({ url: `/api/pool/${update_type}/${poolAddress}`, method: "PUT", body });
  }

  public async swap({ network, query }: { network: number; query: Record<string, string> }) {
    const searchQuery = new URLSearchParams(query).toString();
    return await api.call<SwapResponse>({ url: `/api/0x/quote/${network}?${searchQuery}`, method: "GET" });
  }

  public async create_swap_history({ body }: { body: SwapHistoryBody }) {
    return await api.call<SwapHistoryResponse>({ url: "/api/swap", method: "POST", body });
  }

  public async get_swap_history({ address, network }: { address: Address; network: number }) {
    return await api.call<SwapHistoryResponse[]>({
      url: `/api/swap?user=${address}&network=${network}`,
      method: "GET"
    });
  }

  public async get_farms() {
    return await api.call<FarmsResponse[]>({
      url: `/api/farm`,
      method: "GET"
    });
  }

  public async get_stakes() {
    return await api.call<StakesResponse[]>({
      url: `/api/stake`,
      method: "GET"
    });
  }

  public async get_configs() {
    return await api.call({
      url: `/api/swap/config`,
      method: "GET"
    }) as ConfigResponse;
  }
}

const fetchers = new Fetchers();
export { fetchers };
