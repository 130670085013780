import { NavLink } from 'react-router-dom';
import { cn } from '~/utils/cn';
import { FiRepeat, FiDroplet, FiGitBranch } from 'react-icons/fi';

const navItems = [
  {
    path: '/swap',
    label: 'TopList',
    icon: FiRepeat,
    gradient: 'from-[#FF6B95] to-[#FF8FAB]'
  },
  {
    path: '/pool',
    label: 'Pool',
    icon: FiDroplet,
    gradient: 'from-[#FF6B95] to-[#FF8FAB]'
  },
  {
    path: '/bridge',
    label: 'Bridge',
    icon: FiGitBranch,
    gradient: 'from-[#FF6B95] to-[#FF8FAB]'
  }
];

export default function MobileNavbar() {
  return (
    <nav className="fixed bottom-0 left-0 right-0 z-50 md:hidden">
      {/* Background */}
      <div className="absolute inset-0 bg-white border-t border-[#FFE4EA]" />

      {/* Navigation Items */}
      <div className="relative flex items-center justify-around h-16 px-4">
        {navItems.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            className="relative flex flex-col items-center py-2 w-[72px]"
          >
            {({ isActive }) => (
              <>
                {/* Icon Container */}
                <div
                  className={cn(
                    "mb-1 p-2 rounded-xl transition-all duration-200",
                    {
                      "bg-[#FF6B95] -translate-y-4 shadow-[0_4px_12px_rgba(255,107,149,0.25)]": isActive,
                      "bg-[#FFE4EA]": !isActive
                    }
                  )}
                >
                  <item.icon 
                    className={cn("w-5 h-5", {
                      "text-white": isActive,
                      "text-[#FF6B95]": !isActive
                    })} 
                  />
                </div>

                {/* Label */}
                <span
                  className={cn("text-xs font-semibold transition-colors", {
                    "text-[#FF6B95]": isActive,
                    "text-[#805B64]": !isActive
                  })}
                >
                  {item.label}
                </span>

                {/* Active Dot */}
                {isActive && (
                  <div className="absolute -top-0.5 right-3 w-2 h-2 bg-[#FF6B95] rounded-full" />
                )}
              </>
            )}
          </NavLink>
        ))}
      </div>

      {/* Safe Area for iOS */}
      <div className="h-[env(safe-area-inset-bottom)] bg-white" />
    </nav>
  );
} 