
import { ConnectKitButton } from "connectkit";
import { useAccount } from "wagmi";
import Button from "~/components/shared/Button";
import Icon from "~/components/shared/Icon";
import { truncate } from "~/utils/parse";

interface ConnectWalletProps {
  className?: string;
}

export default function ConnectWallet({ className }: ConnectWalletProps) {
  const { address, isConnected } = useAccount();

  return (
    <ConnectKitButton.Custom>
      {({ show, isConnecting }) => (
        <Button 
          type="button" 
          variant="border" 
          onClick={show} 
          className={`px-3 h-11 gap-x-2 ${className || ''}`} 
          disabled={isConnecting}
        >
          {isConnecting ? (
            <Icon icon="spinner" className="animate-spin" />
          ) : (
            <Icon icon="wallet" />
          )}
          <span className="hidden sm:flex items-center font-sans">
            {isConnected && address ? truncate(address) : "Connect Wallet"}
          </span>
        </Button>
      )}
    </ConnectKitButton.Custom>
  );
}
