// config
import { COINS } from "~/config/web3";

// types
import { CoinGeckoCoinList } from "~/types/coingecko.types";
import { Address } from "~/types/global.types";

export function parse<T>(data: T) {
  if (!data) {
    throw new Error("[Parse] Data must be provided.");
  }

  const parsed = typeof data === "string" && (data.startsWith("{") || data.startsWith("[")) ? JSON.parse(data) : data;
  return parsed;
}

// stringify message before send
export function stringify<T>(data: T) {
  if (!data) {
    throw new Error("[Parse] Data must be provided.");
  }

  const stringified = typeof data !== "string" ? JSON.stringify(data) : data;
  return stringified;
}

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * @param address full address to truncate
 * @returns truncated address
 */
export function truncate(address: string | Address) {
  if (typeof address !== "string") {
    throw new Error("[Parse] Address must be provided.");
  }

  const ETH_REGEX = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;

  const match = address.match(ETH_REGEX);

  if (!match) {
    throw new Error("[Parse] Address must be match ethereum regex.");
  }

  return `${match[1]}••••${match[2]}`;
}

/**
 * Optional formatter parameters
 */
type FormatterOptions = Intl.NumberFormatOptions;

/**
 * Formats number as more human-readable shape
 * @param value number that will be formatted
 * @returns formatted number
 */
export function format(value: number | undefined, options?: FormatterOptions) {
  if (typeof value !== "number") {
    return value;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options
  });

  return formatter.format(value);
}

/**
 * Filter given list by chain id and return a new list
 * @param chainId number chain id to be filtered
 * @returns filtered list
 */
export function filterListByChain(list: CoinGeckoCoinList | undefined, chainId: number) {
  if (!list) return;
  if (!chainId) return list;

  const chainAddress = COINS.find(coin => coin.chainId === chainId)?.address;
  const filteredList = list.filter(item => !!item.addresses[chainAddress as keyof typeof item.addresses]);

  return filteredList;
}

/**
 * Parse given number to limited digits
 * Ex. 0.00123456 => 0.001234
 * @param number number | string | undefined number to be parse
 * @param maxDigits number | undefined number to limit digits, default is 4
 * @returns parsed number as string
 */
export function formatNumber(number: string | number | undefined, maxDigits = 4): string {
  if (!number) return "0";
  if (!number.toString().includes(".")) return number.toString();

  const integer = number.toString().split(".")[0];
  let double = number.toString().split(".")[1];

  for (let i = 0; i < double.length - 1; i++) {
    if (double[i] === "0") continue;

    double = double.slice(0, i + maxDigits);

    // deleting trailing zeros
    for (let i = double.length - 1; i > 0; i--) {
      if (double[i] === "0") continue;

      double = double.slice(0, i + 1);
      break;
    }
    break;
  }

  const result = integer + "." + double;

  return result;
}
