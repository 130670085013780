import React from "react";
import { Link, useParams } from "react-router-dom";
import { waitForTransaction, writeContract } from "wagmi/actions";
import { useAccount, useBalance, useChainId, useContractRead } from "wagmi";
import { Interface, ethers } from "ethers";
import { useAppStore } from "~/store";

// components
import { Container, Section } from "~/components/Layout";
import TabNavigator from "~/components/TabNavigator";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "~/components/shared/Accordion";
import Button from "~/components/shared/Button";
import { Card, CardHeader, CardTitle } from "~/components/shared/Card";
import Icon from "~/components/shared/Icon";
import { Slider } from "~/components/shared/Slider";

// config
import { ABIS, COINS, nativeTokenAddress } from "~/config/web3";

// types
import { PoolResponse } from "~/types/pool.types";
import { Address, Network } from "~/types/global.types";

// utils
import { cn } from "~/utils/cn";
import { fetchers } from "~/utils/fetchers";
import { formatNumber } from "~/utils/parse";
import * as web3_utils from "web3-utils";
import { errorToast } from "~/utils/error";

// hooks
import { useToast } from "~/hooks/useToast";

export default function RemoveLiquidity() {
  const { pair } = useParams();
  const { toast } = useToast();
  const { address } = useAccount();
  const chainId = useChainId();
  const coins = useAppStore(store => store.swap.coins);

  const network = COINS.find(c => c.chainId === chainId)?.address;

  const [pair0, pair1] = pair?.split("-") as string[];

  const token0 = coins?.find(c => c.symbol === pair0);
  const token1 = coins?.find(c => c.symbol === pair1);

  const presets = ["25", "50", "75", "100"];
  const [percentage, setPercentage] = React.useState(0);
  const handlePreset = React.useCallback((preset: string) => setPercentage(Number(preset)), []);

  const [isLoading, setisLoading] = React.useState(false);
  const [isError, setisError] = React.useState(false);
  const [pool, setPool] = React.useState<PoolResponse | undefined>(undefined);

  const [removeLoading, setRemoveLoading] = React.useState(false);

  // fetch pool on load
  React.useEffect(() => {
    const getPool = async () => {
      setisLoading(true);

      const response = await fetchers.get_pool(pair || "");
      if (!response) {
        setisError(true);
        setisLoading(false);
        return;
      }

      setPool(response as PoolResponse);
      setisLoading(false);
    };

    getPool();
  }, []);

  // total suply
  const { data: totalLP } = useContractRead({
    abi: ABIS.lpAbi.abi,
    address: pool?.pool_address as Address,
    functionName: "totalSupply"
  });

  // balance LP of user
  const { data: userLP } = useContractRead({
    abi: ABIS.erc20Abi.abi,
    address: pool?.pool_address as Address,
    functionName: "balanceOf",
    args: [address]
  });

  // token 1 amount
  const { data: token0_amount } = useBalance({
    address: pool?.pool_address as Address,
    token: pool?.token0_address as Address
  });

  // token 2 amount
  const { data: token1_amount } = useBalance({
    address: pool?.pool_address as Address,
    token: pool?.token1_address as Address
  });

  // calculate earn token by percentage
  const calculatedToken0 = React.useMemo(() => {
    return (Number(token0_amount?.formatted) * percentage) / 100;
  }, [percentage]);

  const calculatedToken1 = React.useMemo(() => {
    return (Number(token1_amount?.formatted) * percentage) / 100;
  }, [percentage]);

  // remove liquidity
  const remove = async () => {
    setRemoveLoading(true);

    try {
      // approve LP token to remove
      const { hash: approveTansaction } = await writeContract({
        abi: ABIS.lpAbi.abi,
        address: pool?.pool_address as Address,
        functionName: "approve",
        args: [
          ABIS.balanceRouterAbi.address as Address, // to address
          ((Number(userLP) * percentage) / 100).toFixed() // liquidity to remove
        ]
      });

      await waitForTransaction({ hash: approveTansaction });

      let transactionHash = "";

      if (
        token0?.addresses[network as Network] === nativeTokenAddress ||
        token1?.addresses[network as Network] === nativeTokenAddress
      ) {
        const { hash } = await writeContract({
          abi: ABIS.balanceRouterAbi.abi,
          address: ABIS.balanceRouterAbi.address as Address,
          functionName: "removeLiquidityETH",
          args: [
            ...(token0?.addresses[network as Network] === nativeTokenAddress ? [] : [pool?.token0_address]),
            ...(token1?.addresses[network as Network] === nativeTokenAddress ? [] : [pool?.token1_address]),
            ((Number(userLP) * percentage) / 100).toFixed(), // liquidity to remove
            web3_utils.toWei("0.0000001", "ether"), // token amount min
            web3_utils.toWei("0.0000001", "ether"), // eth amount min
            address, // to address
            Math.floor(Date.now() / 1000) + 60 * 10 // deadline
          ]
        });

        transactionHash = hash;
      } else {
        const { hash } = await writeContract({
          abi: ABIS.balanceRouterAbi.abi,
          address: ABIS.balanceRouterAbi.address as Address,
          functionName: "removeLiquidity",
          args: [
            pool?.token0_address, // token 0 address
            pool?.token1_address, // token 1 address
            ((Number(userLP) * percentage) / 100).toFixed(), // liquidity to remove
            web3_utils.toWei("0.0000001", "ether"), // minimum token 0 amount
            web3_utils.toWei("0.0000001", "ether"), // minimum token 1 amount
            address, // to address
            Math.floor(Date.now() / 1000) + 60 * 10 // deadline
          ]
        });

        transactionHash = hash;
      }

      const receipt = await waitForTransaction({ hash: transactionHash as Address });
      const iface = new Interface(ABIS.pairAbi.abi as ethers.InterfaceAbi);
      const logParams = iface.decodeEventLog("Burn", receipt.logs[5].data, receipt.logs[5].topics);
      const token0_amount = Number(web3_utils.fromWei(Number(logParams[1]), "ether"));
      const token1_amount = Number(web3_utils.fromWei(Number(logParams[2]), "ether"));

      const body = { token0_amount, token1_amount };
      await fetchers.update_pool({ update_type: "remove", poolAddress: pool?.pool_address as string, body });
    } catch (error) {
      setRemoveLoading(false);

      errorToast(error as Error);
    }

    setRemoveLoading(false);
    return toast({
      title: "Success",
      description: "Liquidity removed succesfully"
    });
  };

  return (
    <div className="flex flex-1 flex-col pt-24 py-12 font-semibold">
      <Container>
        <Section>
          <div className="flex flex-1 flex-col justify-center items-center w-full max-w-[1140px] mx-auto gap-y-6">
            <TabNavigator />

            <Card className="flex-1 w-full p-8">
              <CardHeader className="pt-0">
                <div className="flex items-center justify-between gap-x-6">
                  <Link to="/pool">
                    <Button type="button" className="w-10 h-10 p-0 rounded-full" variant="border">
                      <Icon icon="arrow-left" />
                    </Button>
                  </Link>

                  <CardTitle className="font-bold">Remove Liquidity</CardTitle>

                  <span className="flex w-10 opacity-0" />
                </div>
              </CardHeader>

              <div className="w-full flex justify-center mt-10">
                {isLoading ? (
                  <Icon icon="spinner" className="animate-spin" />
                ) : isError || !pool ? (
                  <p className="text-sm text-muted-foreground font-medium">An error occured while getting data</p>
                ) : (
                  <Card className="flex flex-col gap-6 max-w-[400px] p-6">
                    {/* Amount */}
                    <Accordion type="single" collapsible>
                      <AccordionItem value="amount">
                        <AccordionTrigger className="font-semibold">Amount</AccordionTrigger>
                        <AccordionContent>
                          <div className="flex flex-col gap-3 rounded-lg bg-secondary p-4">
                            <div className="w-full flex justify-between">
                              <span className="text-foreground/50">Total pool tokens</span>
                              <span>{formatNumber(web3_utils.fromWei(Number(totalLP), "ether"))}</span>
                            </div>

                            <div className="w-full flex justify-between">
                              <span className="text-foreground/50">Your pool tokens</span>
                              <span>{formatNumber(web3_utils.fromWei(Number(userLP), "ether"))}</span>
                            </div>

                            <div className="w-full flex justify-between">
                              <span className="text-foreground/50">Pooled {pair0}</span>
                              <span>{`${formatNumber(token0_amount?.formatted)} ${pair0}`}</span>
                            </div>

                            <div className="w-full flex justify-between">
                              <span className="text-foreground/50">Pooled {pair1}</span>
                              <span>{`${formatNumber(token1_amount?.formatted)} ${pair1}`}</span>
                            </div>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>

                    {/* Percentage */}
                    <div>
                      <div className="w-full flex flex-col gap-2">
                        <span className="text-2xl">{percentage}%</span>
                        <Slider
                          value={[percentage]}
                          max={100}
                          step={1}
                          className="mt-2 mb-6"
                          onValueChange={e => setPercentage(Number(e[0]))}
                        />
                      </div>

                      <div className="flex gap-4">
                        {presets.map(preset => {
                          return (
                            <Button
                              key={preset}
                              type="button"
                              variant="border"
                              size="small"
                              className={cn("text-xs font-bold w-full whitespace-nowrap", {
                                "bg-secondary": Number(preset) === percentage
                              })}
                              onClick={() => handlePreset(preset)}
                            >
                              {preset === "100" ? "MAX" : `${preset}%`}
                            </Button>
                          );
                        })}
                      </div>
                    </div>

                    {/* Earn */}
                    <div className="w-full flex flex-col gap-3">
                      <p>You will receive</p>

                      <div className="flex items-center justify-between">
                        <span className="text-foreground/50">{formatNumber(calculatedToken0)}</span>
                        <div className="flex items-center gap-2">
                          <img src={pool?.token0_icon} alt="" className="w-4 h-4" />
                          <span>{pair0}</span>
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <span className="text-foreground/50">{formatNumber(calculatedToken1)}</span>
                        <div className="flex items-center gap-2">
                          <img src={pool?.token1_icon} alt="" className="w-4 h-4" />
                          <span>{pair1}</span>
                        </div>
                      </div>
                    </div>

                    <Button
                      type="button"
                      variant="invert"
                      loading={removeLoading}
                      disabled={!calculatedToken0 || !calculatedToken1 || removeLoading}
                      onClick={remove}
                    >
                      Remove Liquidity
                    </Button>
                  </Card>
                )}
              </div>
            </Card>
          </div>
        </Section>
      </Container>
    </div>
  );
}
