import { motion } from "framer-motion";
import { FiArrowUpRight, FiArrowDownRight } from "react-icons/fi";
import logo from "~/assets/images/logo.png";
export default function TokenStats() {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-screen-xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-10"
        >
          {/* Token Info */}
          <div className="flex items-center justify-center gap-3 mb-6">
            <img 
              src={logo}
              alt="CREPE"
              className="w-12 h-12 rounded-full"
            />
            <div className="text-left">
              <h2 className="text-2xl font-bold text-[#805B64]">
                CREPE
              </h2>
              <div className="flex items-center gap-2">
                <span className="text-sm text-[#805B64]/70">$CREPE</span>
                <span className="text-xs px-2 py-0.5 bg-[#FFE4EA] rounded-full text-[#FF6B95]">
                  BSC
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center gap-4 mb-8">
            <div className="text-center">
              <div className="text-2xl font-bold text-[#FF6B95]">$NaN</div>
              <div className="text-sm text-[#805B64]/70">Current Price</div>
            </div>
            <div className="h-8 w-px bg-pink-100"></div>
            <div className="text-center">
              <div className="text-2xl font-bold text-green-500">NaN</div>
              <div className="text-sm text-[#805B64]/70">24h Change</div>
            </div>
          </div>
        </motion.div>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
          {/* TVL */}
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="bg-[#FFF5F7] rounded-xl p-4"
          >
            <div className="flex items-center justify-between mb-2">
              <span className="text-xs md:text-sm text-[#805B64]/70">TVL</span>
              <div className="flex items-center text-green-500 text-xs">
                <FiArrowUpRight className="w-3 h-3" />
                <span>+2.5%</span>
              </div>
            </div>
            <div className="text-lg md:text-xl font-bold text-[#805B64]">$NaN</div>
          </motion.div>

          {/* Volume */}
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.1 }}
            className="bg-[#FFF5F7] rounded-xl p-4"
          >
            <div className="flex items-center justify-between mb-2">
              <span className="text-xs md:text-sm text-[#805B64]/70">24h Volume</span>
              <div className="flex items-center text-red-500 text-xs">
                <FiArrowDownRight className="w-3 h-3" />
                <span>-1.2%</span>
              </div>
            </div>
            <div className="text-lg md:text-xl font-bold text-[#805B64]">$NaN</div>
          </motion.div>

          {/* Transactions */}
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            className="bg-[#FFF5F7] rounded-xl p-4"
          >
            <div className="flex items-center justify-between mb-2">
              <span className="text-xs md:text-sm text-[#805B64]/70">Transactions</span>
              <div className="flex items-center text-green-500 text-xs">
                <FiArrowUpRight className="w-3 h-3" />
                <span>+5.8%</span>
              </div>
            </div>
            <div className="text-lg md:text-xl font-bold text-[#805B64]">NaN</div>
          </motion.div>

          {/* Users */}
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.3 }}
            className="bg-[#FFF5F7] rounded-xl p-4"
          >
            <div className="flex items-center justify-between mb-2">
              <span className="text-xs md:text-sm text-[#805B64]/70">Users</span>
              <div className="flex items-center text-green-500 text-xs">
                <FiArrowUpRight className="w-3 h-3" />
                <span>+3.1%</span>
              </div>
            </div>
            <div className="text-lg md:text-xl font-bold text-[#805B64]">NaN</div>
          </motion.div>
        </div>

        {/* Additional Stats */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          {/* Chart */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="bg-[#FFF5F7] rounded-xl p-4"
          >
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <img 
                  src={logo}
                  alt="CREPE"
                  className="w-6 h-6 rounded-full"
                />
                <span className="font-medium text-[#805B64]">CREPE/USDT</span>
              </div>
              <span className="text-xs text-[#805B64]/70">Last 24 hours</span>
            </div>
            <div className="h-[180px] flex items-center justify-center">
              <span className="text-sm text-[#805B64]/50">Chart Coming Soon</span>
            </div>
          </motion.div>

          {/* Stats Table */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="bg-[#FFF5F7] rounded-xl p-4"
          >
            <div className="flex items-center gap-2 mb-4">
              <img 
                src={logo}
                alt="CREPE"
                className="w-6 h-6 rounded-full"
              />
              <span className="font-medium text-[#805B64]">Token Info</span>
            </div>
            <div className="space-y-3">
              <div className="flex justify-between py-2 border-b border-pink-50">
                <span className="text-sm text-[#805B64]/70">Market Cap</span>
                <span className="text-sm font-medium text-[#805B64]">$NaN</span>
              </div>
              <div className="flex justify-between py-2 border-b border-pink-50">
                <span className="text-sm text-[#805B64]/70">Circulating Supply</span>
                <span className="text-sm font-medium text-[#805B64]">NaN CREPE</span>
              </div>
              <div className="flex justify-between py-2 border-b border-pink-50">
                <span className="text-sm text-[#805B64]/70">Total Supply</span>
                <span className="text-sm font-medium text-[#805B64]">NaN CREPE</span>
              </div>
              <div className="flex justify-between py-2">
                <span className="text-sm text-[#805B64]/70">Holders</span>
                <span className="text-sm font-medium text-[#805B64]">NaN</span>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}