import { AbiItem } from "~/types/abi.types";
import { createPublicClient, http } from 'viem';
import { bsc } from 'viem/chains';

// Abis
import SwapRouterAbi from "~/ABIS/SwapRouterAbi.json";
import PairAbi from "~/ABIS/Pair.json";
import BalanceRouter from "~/ABIS/BalanceRouter.json";
import BalanceFactory from "~/ABIS/BalanceFactory.json";
import ERC20 from "~/ABIS/ERC20.json";
import LPAbi from "~/ABIS/LP.json";
import Masterchef from "~/ABIS/Masterchef.json";

// types
import { Address } from "~/types/global.types";
import { CoinsType } from "~/types/0x.types";

// BSC için public client oluştur
export const provider = createPublicClient({
  chain: bsc,
  transport: http('https://bsc-dataseed1.binance.org')
});

// Diğer chainler için de provider'lar eklenebilir
export const SUPPORTED_CHAINS = {
  BSC: {
    id: 56,
    name: 'BNB Smart Chain',
    rpcUrl: 'https://bsc-dataseed1.binance.org',
    explorer: 'https://bscscan.com'
  }
};

export const SIGN_MESSAGE = (date: string) =>
  `I want to login on Crepe Finance at ${date}. I accept the Terms of Service https://docs.crepefinance.com/views/About/termsofuse.html and I am at least 13 years old.`;

export const specialTokenAddress = "0x5cf8ea4278f689b301c4a17ddca9d5ec8b0b0511"; // BLN token address
export const nativeTokenAddress = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

export const farmGainToken = {
  symbol: "BNB",
  address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" // WBNB address
};

export const ExplorerMap: { [key: number]: string } = {
  1: "https://etherscan.io/address/",
  56: "https://bscscan.com/address/",
  42161: "https://arbiscan.io/token/",
  137: "https://polygonscan.com/token/",
  43114: "https://avascan.info/blockchain/c/token/",
  250: "https://ftmscan.com/token/",
  8453: "https://basescan.org/token/",
  10: "https://optimistic.etherscan.io/token/"
};

export const COINS: CoinsType[] = [
  {
    name: "Ethereum",
    ccy: "ETH",
    network: "ethereum",
    chainId: 1,
    address: "0x1"
  },
  {
    name: "BSC",
    ccy: "BNB",
    network: "bsc",
    chainId: 56,
    address: "0x38"
  },
  {
    name: "Arbitrum",
    ccy: "ETH",
    network: "arbitrum-one",
    chainId: 42161,
    address: "0xa4b1"
  },
  {
    name: "Polygon",
    ccy: "MATIC",
    network: "polygon-pos",
    chainId: 137,
    address: "0x89"
  },
  {
    name: "Avalanche",
    ccy: "AVAX",
    network: "avalanche",
    chainId: 43114,
    address: "0xa86a"
  },
  {
    name: "Fantom",
    ccy: "FTM",
    network: "fantom",
    chainId: 250,
    address: "0xfa"
  },
  {
    name: "Base",
    ccy: "ETH",
    network: "base",
    chainId: 8453,
    address: "0x2105"
  },
  {
    name: "Optimism",
    ccy: "ETH",
    network: "optimism",
    chainId: 10,
    address: "0x2105"
  },
  {
    name: "HECO",
    ccy: "HT",
    network: "heco",
    chainId: 128,
    address: "0xa"
  }
];

export const ABIS: Record<string, { abi: AbiItem[]; address?: Address }> = {
  swapRouterAbi: {
    abi: SwapRouterAbi,
    address: "0xb93a8d89dEb1615894991AD8D0cCd54E66a4d378"
  },
  pairAbi: {
    abi: PairAbi
  },
  erc20Abi: {
    abi: ERC20
  },
  lpAbi: {
    abi: LPAbi
  },
  balanceRouterAbi: {
    abi: BalanceRouter,
    address: "0x1f635a755BE389b7686bdCAF7ff1E1Ab0CbDBc66"
  },
  balanceFactoryAbi: {
    abi: BalanceFactory,
    address: "0xa65aaE8ACB84e7657AbcE053b86deaCBF5814863"
  },
  masterchefAbi: {
    abi: Masterchef,
    address: "0x0d14812bF3d64fCc2f319De8175c5fc0EEb46C33"
  }
};

export const WEB3ERRORS = [
  "INSUFFICIENT_OUTPUT_AMOUNT",
  "User denied transaction",
  "INVALID_PATH",
  "EXCESSIVE_INPUT_AMOUNT",
  "INSUFFICIENT_LIQUIDITY",
  "INSUFFICIENT_AMOUNT",
  "INSUFFICIENT_INPUT_AMOUNT",
  "IDENTICAL_ADDRESSES",
  "ZERO_ADDRESS",
  "ds-math-mul-overflow",
  "ds-math-add-overflow",
  "ds-math-sub-underflow",
  "ETH_TRANSFER_FAILED",
  "TRANSFER_FROM_FAILED",
  "TRANSFER_FAILED",
  "APPROVE_FAILED"
];

export const paymentTokens: Record<string, string>[] = [
  {
    symbol: "BNB",
    "0x38": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
  },
  {
    symbol: "ETH",
    "0x1": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    "0xa4b1": "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
    "0x2105": "0x4200000000000000000000000000000000000006",
    "0xa": "0x4200000000000000000000000000000000000006"
  },
  {
    symbol: "AVAX",
    "0xa86a": "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7"
  },
  {
    symbol: "FTM",
    "0xfa": "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83"
  }
];
