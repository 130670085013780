import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Resimleri import edelim
import announcement1 from '~/assets/images/tradecrypto-removebg-preview.png';
import announcement2 from '~/assets/images/welcometocrepedex-removebg-preview.png';
import announcement3 from '~/assets/images/logo.png';

const announcements = [
  {
    id: 1,
    title: "Welcome to Crepe DEX",
    description: "Experience seamless cross-chain trading with the best rates",
    image: announcement1
  },
  {
    id: 2,
    title: "New Tokens Listed",
    description: "Trade the latest tokens with deep liquidity pools",
    image: announcement2
  },
  {
    id: 3,
    title: "Lower Trading Fees",
    description: "Enjoy reduced fees on all cross-chain swaps",
    image: announcement3
  }
];

export default function AnnouncementSlider() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % announcements.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="w-full bg-gradient-to-r from-[#FFF5F7] to-white">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="relative h-[300px] overflow-hidden rounded-3xl">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentSlide}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
              className="absolute inset-0"
            >
              <div className="relative h-full flex items-center">
                {/* Gradient Overlay */}
                <div className="absolute inset-0 bg-gradient-to-r from-[#FFE4EA] to-transparent" />

                {/* Content */}
                <div className="relative z-10 flex flex-col md:flex-row items-center justify-between w-full px-8 md:px-12">
                  <div className="md:w-1/2 space-y-4">
                    <motion.h2 
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2 }}
                      className="text-3xl md:text-4xl font-bold text-[#805B64]"
                    >
                      {announcements[currentSlide].title}
                    </motion.h2>
                    <motion.p 
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.3 }}
                      className="text-lg text-[#805B64]/80"
                    >
                      {announcements[currentSlide].description}
                    </motion.p>
                    <motion.button
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 }}
                      className="px-6 py-3 bg-[#FF6B95] text-white rounded-xl font-semibold 
                               hover:bg-[#FF6B95]/90 transition-colors mt-4"
                    >
                      Learn More
                    </motion.button>
                  </div>

                  {/* Image */}
                  <div className="md:w-1/2 flex justify-end">
                    <motion.img
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ delay: 0.3 }}
                      src={announcements[currentSlide].image}
                      alt={announcements[currentSlide].title}
                      className="w-[300px] h-[300px] object-contain"
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>

          {/* Dots */}
          <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {announcements.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-2 h-2 rounded-full transition-all ${
                  currentSlide === index 
                    ? 'w-8 bg-[#FF6B95]' 
                    : 'bg-[#FFE4EA]'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
} 