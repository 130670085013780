// wagmi
import { createConfig, configureChains } from "wagmi";

// chains
import { arbitrum, avalanche, base, bsc, fantom, mainnet, optimism, polygon } from "wagmi/chains";

// providers
import { publicProvider } from "wagmi/providers/public";

// connectkit
import { getDefaultConfig } from "connectkit";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, arbitrum, avalanche, base, fantom, optimism, mainnet, polygon],
  [publicProvider()]
);

const wagmi = createConfig(
  getDefaultConfig({
    autoConnect: true,
    appName: "Swap | Crepe Finance",
    walletConnectProjectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
    publicClient,
    webSocketPublicClient,
    chains
  })
);

export default wagmi;
