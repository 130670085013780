import React from "react";

// react-query
import { useQuery, UseQueryResult } from "react-query";

// store
import { useAppStore } from "~/store";

// utils
import { fetchers } from "~/utils/fetchers";

// types
import type { Result } from "~/types/api.types";
import type { ContractStatsResponse } from "~/types/contract.types";

/**
 * Get contract stats hook
 */
type ContractStatsQuery = Result<ContractStatsResponse>;
export function useContractStats(): UseQueryResult<ContractStatsQuery> {
  const contract = useAppStore(store => store.contract);
  const query = useQuery<ContractStatsQuery>("contract_stats", fetchers.get_contract_stats);

  React.useEffect(() => {
    if (query.isFetched && query.isSuccess) {
      const data = query.data as ContractStatsResponse;

      contract.set_total_supply(data.total_supply);
      contract.set_circulating_supply(data.circulating_supply);
    }
  }, [query]);

  return query;
}
