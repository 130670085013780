import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import axios from 'axios';
import { motion } from 'framer-motion';

// Trending token tipi
interface TrendingToken {
  address: string;
  decimals: number;
  liquidity: number;
  logoURI: string | null;
  name: string;
  symbol: string;
  volume24hUSD: number;
  volume24hChangePercent: number;
  fdv: number;
  marketcap: number;
  rank: number;
  price: number;
  price24hChangePercent: number;
}

// API yanıt tipi
interface ApiResponse {
  data: {
    updateUnixTime: number;
    updateTime: string;
    tokens: TrendingToken[];
    total: number;
  };
  success: boolean;
}

export default function TokenSlider() {
  const [trendingTokens, setTrendingTokens] = useState<TrendingToken[]>([]);
  const [loading, setLoading] = useState(true);
  const sliderRef = useRef<HTMLDivElement>(null);

  // Trending token verilerini çek
  useEffect(() => {
    const fetchTrendingTokens = async () => {
      try {
        setLoading(true);
        
        const response = await axios.get<ApiResponse>(
          'https://public-api.birdeye.so/defi/token_trending?sort_by=rank&sort_type=asc&offset=0&limit=20',
          {
            headers: {
              'X-API-KEY': 'f4a5ddb746be41fe8f64db79902e29e5',
              'x-chain': 'bsc',
              'accept': 'application/json'
            }
          }
        );
        
        if (response.data.success && response.data.data.tokens) {
          setTrendingTokens(response.data.data.tokens);
        }
      } catch (err) {
        console.error('API hatası:', err);
      } finally {
        setLoading(false);
      }
    };
    
    fetchTrendingTokens();
  }, []);

  // Otomatik kaydırma
  useEffect(() => {
    if (!trendingTokens.length || loading) return;
    
    const slider = sliderRef.current;
    if (!slider) return;
    
    // Kaydırma hızını artırmak için süreyi azalttık (30s -> 20s)
    const keyframes = `
      @keyframes scroll {
        0% { transform: translateX(0); }
        100% { transform: translateX(-50%); }
      }
    `;
    
    const style = document.createElement('style');
    style.innerHTML = keyframes;
    document.head.appendChild(style);
    
    // Animasyon süresini azaltarak hızlandırdık
    slider.style.animation = 'scroll 20s linear infinite';
    
    return () => {
      document.head.removeChild(style);
    };
  }, [trendingTokens, loading]);

  // Fiyat değişimini formatlama
  const formatPriceChange = (change: number) => {
    const isPositive = change >= 0;
    return (
      <div className={`flex items-center gap-1 ${isPositive ? 'text-green-500' : 'text-red-500'} font-medium`}>
        {isPositive ? <FiArrowUp className="w-3 h-3" /> : <FiArrowDown className="w-3 h-3" />}
        {Math.abs(change).toFixed(2)}%
      </div>
    );
  };

  if (loading) {
    return (
      <div className="h-12 bg-gradient-to-r from-[#FFE4EA] to-[#FFF5F7] flex items-center justify-center">
        <div className="w-5 h-5 border-2 border-[#FF6B95] border-t-transparent rounded-full animate-spin mr-2"></div>
        <span className="text-[#805B64] font-medium">Loading trending tokens...</span>
      </div>
    );
  }

  return (
    <div className="w-full bg-gradient-to-r from-[#FFE4EA] to-[#FFF5F7] border-b border-pink-100 py-2 overflow-hidden shadow-sm">
      <div className="max-w-screen-xl mx-auto px-4 flex items-center">
        {/* Trending başlığı */}

        
        {/* Token slider */}
        <div 
          ref={sliderRef}
          className="flex items-center gap-8 overflow-x-auto scrollbar-none flex-1"
          style={{ 
            scrollBehavior: 'smooth',
            WebkitOverflowScrolling: 'touch',
            // Kaydırma çubuğunu şeffaf yapma stilleri
            msOverflowStyle: 'none',  // IE ve Edge için
            scrollbarWidth: 'thin',   // Firefox için
            scrollbarColor: 'transparent transparent' // Firefox için renk
          }}
        >
          {/* Webkit tarayıcılar (Chrome, Safari) için kaydırma çubuğu stilleri */}
          <style>
            {`
              .scrollbar-none::-webkit-scrollbar {
                width: 6px;
                height: 6px;
                background-color: transparent;
              }
              .scrollbar-none::-webkit-scrollbar-thumb {
                background-color: transparent;
                border-radius: 10px;
              }
              .scrollbar-none::-webkit-scrollbar-track {
                background-color: transparent;
              }
            `}
          </style>
          
          {trendingTokens.map((token, index) => (
            <Link 
              key={token.address}
              to={`/token/${token.address}`}
              className="flex items-center gap-3 flex-shrink-0 hover:bg-white/50 transition-all duration-300 px-3 py-1.5 rounded-full group"
            >
              <motion.div 
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: index * 0.05, duration: 0.3 }}
                className="w-7 h-7 rounded-full bg-white overflow-hidden flex items-center justify-center shadow-sm group-hover:shadow-md transition-shadow"
              >
                {token.logoURI ? (
                  <img src={token.logoURI} alt={token.symbol} className="w-full h-full object-cover" />
                ) : (
                  <div className="text-xs font-bold text-[#FF6B95]">{token.symbol.substring(0, 2)}</div>
                )}
              </motion.div>
              <div className="flex flex-col">
                <div className="font-medium text-[#805B64] group-hover:text-[#FF6B95] transition-colors">
                  {token.symbol}
                </div>
                <div className="text-xs text-[#805B64]/70">
                  ${token.price < 0.01 ? token.price.toFixed(6) : token.price.toFixed(2)}
                </div>
              </div>
              <div className="text-sm">
                {formatPriceChange(token.price24hChangePercent)}
              </div>
              {token.rank <= 3 && (
                <div className="w-5 h-5 bg-[#FFE4EA] rounded-full flex items-center justify-center ml-1">
                  <span className="text-xs font-bold text-[#FF6B95]">#{token.rank}</span>
                </div>
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}