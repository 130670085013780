import { motion } from "framer-motion";
import { FiTwitter, FiMessageCircle, FiGlobe, FiInstagram } from "react-icons/fi";

const socialLinks = [
  {
    icon: FiTwitter,
    name: "Twitter",
    link: "https://x.com/crepefinance",
    followers: "1.5K+",
    bgClass: "bg-[#FFE4EA]",
    hoverClass: "hover:bg-[#FF6B95]"
  },
  {
    icon: FiMessageCircle,
    name: "Telegram",
    link: "https://t.me/crepedotfinance",
    followers: "1.5K+",
    bgClass: "bg-[#FFE4EA]",
    hoverClass: "hover:bg-[#FF6B95]"
  },
  {
    icon: FiInstagram,
    name: "Instagram",
    link: "https://www.instagram.com/crepefinance",
    followers: "1.5K+",
    bgClass: "bg-[#FFE4EA]",
    hoverClass: "hover:bg-[#FF6B95]"
  },
  {
    icon: FiGlobe,
    name: "TikTok",
    link: "https://www.tiktok.com/@crepefinance",
    followers: "1.5K+",
    bgClass: "bg-[#FFE4EA]",
    hoverClass: "hover:bg-[#FF6B95]"
  }
];

export default function JoinCommunity() {
  return (
    <div className="w-full bg-gradient-to-br from-[#FFF5F7] via-white to-[#FFF5F7] py-20">
      <div className="max-w-screen-xl mx-auto px-4">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-[#805B64] mb-4">
            Join Our Community
          </h2>
          <p className="text-lg text-[#805B64]/80 max-w-2xl mx-auto">
            Be part of our growing ecosystem and stay updated with the latest developments
          </p>
        </motion.div>

        {/* Social Links Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {socialLinks.map((social, index) => (
            <motion.a
              key={social.name}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className="group relative"
            >
              <div className="bg-white rounded-2xl p-6 border border-pink-100 
                            shadow-sm hover:shadow-lg transition-all duration-300">
                {/* Icon Container */}
                <div className={`w-16 h-16 ${social.bgClass} rounded-2xl flex items-center justify-center mb-6
                                group-hover:scale-110 transition-transform duration-300`}>
                  <social.icon className={`w-8 h-8 text-[#FF6B95] group-hover:text-white 
                                      transition-colors duration-300`} />
                </div>

                {/* Content */}
                <h3 className="text-xl font-bold text-[#805B64] mb-2">
                  {social.name}
                </h3>
                <div className="flex items-center justify-between">
                  <span className="text-[#805B64]/70">Followers</span>
                  <span className="text-[#FF6B95] font-semibold">{social.followers}</span>
                </div>

                {/* Hover Effect */}
                <div className={`absolute inset-0 rounded-2xl opacity-0 ${social.hoverClass} 
                              group-hover:opacity-5 transition-opacity duration-300`} />
              </div>
            </motion.a>
          ))}
        </div>
      </div>
    </div>
  );
} 