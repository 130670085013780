// utils
import { cn } from "~/utils/cn";

// lib
import * as Icons from "~/lib/icons";

const match: Record<string, JSX.Element> = {
  "balance-network": <Icons.BalanceNetwork />,
  binance: <Icons.Binance />,
  ethereum: <Icons.Ethereum />,
  polygon: <Icons.Polygon />,
  etherscan: <Icons.Etherscan />,
  bscscan: <Icons.Bscscan />,
  coingecko: <Icons.Coingecko />,
  dextools: <Icons.Dextools />,
  marketcap: <Icons.Marketcap />,

  add: <Icons.Add />,
  "alert-circle": <Icons.AlertCircle />,
  "arrow-down": <Icons.ArrowDown />,
  "arrow-left": <Icons.ArrowLeft />,
  "arrow-right": <Icons.ArrowRight />,
  "arrow-up": <Icons.ArrowUp />,
  bag: <Icons.Bag />,
  bot: <Icons.Bot />,
  box: <Icons.Box />,
  brush: <Icons.Brush />,
  check: <Icons.Check />,
  "chevron-down": <Icons.ChevronDown />,
  "chevron-left": <Icons.ChevronLeft />,
  "chevron-right": <Icons.ChevronRight />,
  "chevron-up": <Icons.ChevronUp />,
  clock: <Icons.Clock />,
  copy: <Icons.Copy />,
  cross: <Icons.Cross />,
  calendar: <Icons.Calendar />,
  "calendar-filled": <Icons.CalendarFilled />,
  calculator: <Icons.Calculator />,
  card: <Icons.Card />,
  database: <Icons.Database />,
  dashboard: <Icons.DashboardSimple />,
  discord: <Icons.Discord />,
  divide: <Icons.Divide />,
  dollar: <Icons.Dollar />,
  drop: <Icons.Drop />,
  eye: <Icons.Eye />,
  external: <Icons.External />,
  facebook: <Icons.Facebook />,
  "filter-horizontal": <Icons.FilterHorizontal />,
  file: <Icons.File />,
  globe: <Icons.Globe />,
  grid: <Icons.Grid />,
  heart: <Icons.Heart />,
  "help-circle": <Icons.HelpCircle />,
  info: <Icons.Info />,
  instagram: <Icons.Instagram />,
  layers: <Icons.Layers />,
  link: <Icons.Link />,
  linkedin: <Icons.Linkedin />,
  lock: <Icons.Lock />,
  "log-out": <Icons.LogOut />,
  mail: <Icons.Mail />,
  maximize: <Icons.Maximize />,
  minimize: <Icons.Minimize />,
  medium: <Icons.Medium />,
  menu: <Icons.Menu />,
  minus: <Icons.Minus />,
  moon: <Icons.Moon />,
  nft: <Icons.NFT />,
  pen: <Icons.Pen />,
  photo: <Icons.Photo />,
  "photo-add": <Icons.PhotoAdd />,
  search: <Icons.Search />,
  settings: <Icons.Settings />,
  sparkle: <Icons.Sparkle />,
  spinner: <Icons.Spinner />,
  sun: <Icons.Sun />,
  swap: <Icons.Swap />,
  tag: <Icons.Tag />,
  telegram: <Icons.Telegram />,
  twitter: <Icons.Twitter />,
  token: <Icons.Token />,
  ufo: <Icons.UFO />,
  unlock: <Icons.Unlock />,
  user: <Icons.User />,
  "user-circular": <Icons.UserCircular />,
  reddit: <Icons.Reddit />,
  refresh: <Icons.Refresh />,
  repeat: <Icons.Repeat />,
  upload: <Icons.Upload />,
  verification: <Icons.Verification />,
  wallet: <Icons.Wallet />
};

interface IconProps {
  icon: string;
  className?: string;
}

/**
 * @param {string} icon key for icon match
 * @returns {node | null} svg element || null
 */
export default function Icon({ icon, className }: IconProps) {
  if (typeof icon !== "string" || !match[icon.toLowerCase()]) return null;

  return <span className={cn("icon flex w-5 h-5", className)}>{match[icon.toLowerCase()]}</span>;
}
