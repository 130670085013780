// immer
import { produce } from "immer";

// types
import type { ImmerStateCreator } from "~/store";
import type { TokenPrice } from "~/types/coingecko.types";

type State = {
  usdtPrices: Map<string, number>;
  stakedOnly: boolean;
  search: string;
};

type Actions = {
  set_prices: (prices: TokenPrice) => void;
  set_staketonly: (checked: boolean) => void;
  set_search: (search: string) => void;
};

export type FarmsSlice = State & Actions;

export const createFarmsSlice: ImmerStateCreator<FarmsSlice> = set => ({
  usdtPrices: new Map([]),
  stakedOnly: false,
  search: "",

  set_prices: prices => {
    set(
      produce(state => {
        Object.entries(prices).map(([key, value]) => {
          state.farms.usdtPrices.set(key, value.usd);
        });
      })
    );
  },
  set_staketonly: checked => {
    set(
      produce(state => {
        state.farms.stakedOnly = checked;
      })
    );
  },
  set_search: search => {
    set(
      produce(state => {
        state.farms.search = search;
      })
    );
  }
});
