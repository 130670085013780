import React from "react";

// react-query
import { QueryClient, QueryClientProvider } from "react-query";

// connectkit
import { ConnectKitProvider } from "connectkit";

// wagmi
import { WagmiConfig } from "wagmi";
import { signMessage } from "wagmi/actions";

// contexts
import { EthereumContextProvider } from "~/contexts/ethereum";
import { ThemeContextProvider } from "~/contexts/theme";
import { AuthContext } from "~/contexts/auth";

// config
import wagmi from "~/wagmi";
import { ConnectKitConfig, Constants, Web3 } from "~/config";

// utils
import { storage } from "~/utils/storage";
import { fetchers } from "~/utils/fetchers";

const query_client = new QueryClient();

interface ConnectResponse {
  address?: string | undefined;
  connectorId?: string | undefined;
}

export default function Providers({ children }: { children: React.ReactNode }) {
  const session = React.useContext(AuthContext);

  /* on connection successed */
  async function onAuthenticationSuccess({ address, connectorId }: ConnectResponse) {
    if (address === undefined) return;

    // get current session from storage.
    const [account, signature, timestamp] = [
      storage.get(Constants.Authentication),
      storage.get(Constants.Signature),
      storage.get(Constants.Timestamp)
    ];

    // if there is an existing session, don't create new signature.
    if (account && signature && timestamp) return;

    try {
      // get sign date
      const sign_date = new Date().toISOString();

      // sign message and get signature.
      const signature = await signMessage({ message: Web3.SIGN_MESSAGE(sign_date) });

      // sign account on db.
      await fetchers.sign_account(address);

      // save session to storage.
      storage.set(Constants.Authentication, address);
      storage.set(Constants.Connector, connectorId);
      storage.set(Constants.Signature, signature);
      storage.set(Constants.Timestamp, sign_date);

      // save session to context for real-time updates.
      session.connect(address);
    } catch {
      // if there is an error, disconnect the user to prevent potential errors
      onAuthenticationEnd();
    }
  }

  /* on disconnect triggered */
  function onAuthenticationEnd() {
    // remove session from storage.
    storage.remove(Constants.Authentication);

    // remove session from context.
    session.disconnect();
  }

  return (
    <QueryClientProvider client={query_client}>
      <WagmiConfig config={wagmi}>
        <ConnectKitProvider
          onConnect={onAuthenticationSuccess}
          onDisconnect={onAuthenticationEnd}
          {...ConnectKitConfig}
        >
          <EthereumContextProvider>
            <ThemeContextProvider>{children}</ThemeContextProvider>
          </EthereumContextProvider>
        </ConnectKitProvider>
      </WagmiConfig>
    </QueryClientProvider>
  );
}
