import logo from '../assets/images/logo.png';

const Images = {
  Logo: logo,
  Tokens: {
    ETH: '/src/assets/images/tokens/eth.svg',
    USDT: '/src/assets/images/tokens/usdt.svg',
    BNB: '/src/assets/images/tokens/bnb.svg',
    CAKE: '/src/assets/images/tokens/cake.svg',
    ALM: '/src/assets/images/tokens/alm.svg',
  },
  Networks: {
    Ethereum: '/src/assets/images/networks/ethereum.svg',
    BSC: '/src/assets/images/networks/bsc.svg',
    Polygon: '/src/assets/images/networks/polygon.svg',
    Arbitrum: '/src/assets/images/networks/arbitrum.svg',
    Optimism: '/src/assets/images/networks/optimism.svg',
  },
  Banners: {
    Farm: '/src/assets/images/farm-banner.png',
  }
};

export const CDNImage = (token: string) => {
  return `https://balancenetworkcdn.fra1.cdn.digitaloceanspaces.com/exchange-coins/${token}.png`;
};

export default Images;
